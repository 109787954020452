import React from "react";
import {Controller} from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import {TextFieldElement} from "react-hook-form-mui";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const CheckBox = (props: any) => {
  // const required = props.required ? props.required : "";
  const validationMessage = props.validationMessage ? props.validationMessage : "*Required";
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        // required: props.required,
        validate: {
          required: (value: boolean) => {
            if (props.required && value !== true) {
              return validationMessage;
            }
          }
        }
      }}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => <FormControl
        sx={{maxHeight: "30px"}} fullWidth><FormControlLabel
          label={props.label}
          sx={{
            "& .MuiTypography-root": {
              fontSize: "13px",
              fontWeight: 400,
              fontFamily: "Roboto,Helvetica,Arial,sans-serif",
              color: "rgba(0, 0, 0, 0.6)"
            }
          }}
          control={<Checkbox
            checked={value}
            name={props.name}
            size={"small"}
            color={error ? "error" : "default"}
            onBlur={onBlur}
            value={value}
            ref={ref}
            onChange={onChange}
          />}
        /><FormHelperText style={{
          color: "red",
          marginTop: "-10px",
          display: error ? "block" : "none"
        }}>{validationMessage}</FormHelperText>
      </FormControl>}
    />
  );
};

export default CheckBox;