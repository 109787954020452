import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {NavLink as Link} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarkets} from "services/jotai/requests/requests";
import {v4 as uuidv4} from "uuid";
import {TableCellModalButtonV2} from "components/ui/table/components/cell/ModalV2";

const
  Title = lazy(() => import("components/ui/pagetitle").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar")),
  Brand = lazy(() => import("components/ui/table/components/cell/Brand").then((module) => ({default: module["Brand"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Button = lazy(() => import("@mui/material/Button")),
  Box = lazy(() => import("@mui/material/Box")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Stack = lazy(() => import("@mui/material/Stack"));
export const FccIssuesAndPrograms = (props: any) => {
  const
    [marketsSelect] = useAtom(allTheMarkets),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {getCurrentYear, getArrayOfYears, convertDate} = useDate(),
    {getDocumentQuarter} = useQuarter(),
    [compliant, setCompliance] = useState<any>(false),
    [year, setYear] = useState<any>(getCurrentYear()),
    [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState("All Markets"),
    [quartersArray, setQuartersArray] = useState(getArrayOfQuarters(year.toString(), true)),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    complianceAtom = requestAtom("complianceCompliance", urlPaths.ISSUES_STATUS_COMPLIANCE, {
      year: year,
      market: market,
      quarter: quarter,
      compliant: 1
    }, setErrorState),
    issuesAtom = requestAtom("complianceIssues", urlPaths.ISSUES_ENDPONT, {
      year: year,
      market: market,
      quarter: quarter,
    }, setErrorState),
    [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
    [issuesState, setIssuesState] = useState<any>(() => issuesAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(compliant ? complianceState : issuesState);

  useEffect(() => {
    // Reset the quarter dropdown if the user is switching between years so its not a blank select
    if (quarter !== "All Quarters" && Number(getCurrentYear()) <= year && Number(getCurrentQuarter()) < Number(quarter.split("Q")[1])) {
      setQuarter(`Q${getCurrentQuarter()}`);
    }
  }, [year, quarter, market, compliant]);


  useEffect(() => {
    if (compliant) {
      // Remove the all quarters select option when viewing compliant documents
      if (quarter === "All Quarters") {
        setQuarter(`Q${getCurrentQuarter()}`);
      }
      setQuartersArray(getArrayOfQuarters(year.toString(), false));
    } else {
      setQuartersArray(getArrayOfQuarters(year.toString(), true));
    }
  }, [year, quarter, market, compliant]);


  useEffect(() => {
    if (compliant) {
      return setComplianceState(complianceAtom);
    } else {
      return setIssuesState(issuesAtom);
    }
  }, [year, quarter, market, compliant, isError]);

  useEffect(() => {
    cachedMarketsData();
  }, []);


  const mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          fcc_directory: string,
          containing_folder_link: string,
          date_required: string,
          documents: { link: string, name: string, date: string }[]
        }
      }) => {
        return <Grid key={uuidv4()} container spacing={3}>
          <Grid xs>
            <div style={{lineHeight: "normal"}}><Box
              component="img"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              loading="lazy"
              sx={{
                lineHeight: "normal",
                width: "auto",
                maxHeight: {xs: 90, md: 90},
              }}
              alt={`${params.row.callsign} Logo`}
              src={`/craft/rest/image?facility_id=${params.row.facility_id}`}
            />
            </div>
          </Grid>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
              <span>Market: {params.row.market}</span>
              <span>Callsign: {params.row.callsign}</span>
              <span>Service: {params.row.service}</span>
              <span>Facility ID: {params.row.facility_id}</span>
            </Stack>
          </Grid>
          <Grid xs={12} sx={{marginTop: "-15px", marginBottom: "5px"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
              <Stack direction="column" spacing={1}>{params?.row?.documents?.map((doc: {
                link: string,
                name: string,
                date: string
              }) => {
                return (
                  <Button key={uuidv4()} size={"small"} variant="contained"
                    href={`/craft/view/issues-and-programs/document/${doc.link}`}
                    sx={{boxShadow: 0, width: "100%"}}>
                    <Stack direction="row" spacing={2}>
                      <strong style={{
                        fontWeight: "600",
                        maxWidth: "180px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      }}>{doc.name}</strong>
                      <h5 style={{
                        fontWeight: "100"
                      }}>Q{getDocumentQuarter(doc.date)}</h5>
                      <h5 style={{
                        fontWeight: "100"
                      }}>{convertDate(doc.date)}
                      </h5>
                    </Stack></Button>);
              })} <Button variant="outlined"
                href={params.row.containing_folder_link}
                sx={{boxShadow: 0, width: "100%"}}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target={"_blank"}
                key={uuidv4()}>
                <FolderIcon/>
                <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC ISSUES & PROGRAMS</span>
              </Button></Stack>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    columns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      // flex: 1,
      flex: true,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 80,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
      renderCell: (params: { row: { service: string } }) => {
        return params.row.service;
      }
      // format: (value: number) => value.toLocaleString("en-US"),
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "fcc_directory",
      id: "fcc_directory",
      headerName: "Directory",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      flex: 2,
      // width: 250,
      // align: "center",
      renderCell: (params: { row: { containing_folder_link: string } }) => {
        return <Button variant="outlined"
          href={params.row.containing_folder_link}
          sx={{boxShadow: 0, borderColor: "transparent"}}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          target={"_blank"}
        >
          <FolderIcon/>
          <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC ISSUES & PROGRAMS</span>
        </Button>;
      }
    }, {
      field: "documents",
      id: "documents",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      align: "left",
      cellClassName: "documents",
      renderCell: (params: { row: { documents: [] } }) => {
        const data: any = [];
        if (params?.row?.documents) {
          params?.row?.documents.map((doc: { date: string, file_id: string, upload_filename: string }) => {
            if (compliant && `Q${getDocumentQuarter(doc.date)}` === quarter && doc.upload_filename.includes(".pdf")) {
              data.push(<Link to={`/issues-and-programs/document/${doc.file_id}`}
                style={{
                  color: "#1976d2",
                }} rel="noreferrer"
              >{doc.upload_filename.toString()}</Link>);
            } else if (!compliant && doc.upload_filename.includes(".pdf")) {
              data.push(<Link to={`/issues-and-programs/document/${doc.file_id}`}
                style={{
                  color: "#1976d2",
                }} rel="noreferrer"
              >{doc.upload_filename.toString()}</Link>);
            } else {
              data.push(<p>{doc.upload_filename.toString()}</p>);
            }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }, {
      field: "quarter",
      id: "quarter",
      headerName: "Quarter",
      flex: 1,
      cellClassName: "quarter",
      renderCell: (params: { row: { documents: [] } }) => {
        const data: any = [];
        if (params?.row?.documents) {
          params?.row?.documents.map((doc: { date: string }) => {
            if (compliant && `Q${getDocumentQuarter(doc.date)}` === quarter) {
              data.push(<span>{getDocumentQuarter(doc.date)}</span>);
            } else if (!compliant) {
              data.push(<span>{getDocumentQuarter(doc.date)}</span>);
            }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }, {
      field: "dates",
      id: "dates",
      headerName: "Upload Date",
      flex: 1,
      cellClassName: "date",
      renderCell: (params: any) => {
        const data: any = [];
        if (params?.row?.documents) {
          params?.row?.documents.map((doc: { date: string }) => {
            if (compliant && `Q${getDocumentQuarter(doc.date)}` === quarter) {
              data.push(<span key={uuidv4()}>{convertDate(doc.date)}</span>);
            } else if (!compliant) {
              data.push(<span key={uuidv4()}>{convertDate(doc.date)}</span>);
            }
          });
        }
        return <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={-2.5}
        >{data}</Stack>;
      }
    }],

    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload PDF",
        type: "modal",
        modal: {
          modalTitle: "Upload A PDF",
          modalForm: FccIssuesAndProgramsForm,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [
                // {
                //   variant: "contained",
                //   color: null,
                //   name: "Save For Later",
                //   type: "",
                //   // disabled: null,
                //   // onClick: null
                // },
                {
                  variant: "contained",
                  color: null,
                  name: "Upload To FCC",
                  type: "submit",
                }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/"
      }]
    },
    topperProps: any = {
      title: "Issues & Programs",
      inputs: [{
        label: "Show All With Docs?",
        type: "switch",
        defaultValue: false,
        state: compliant,
        setState: setCompliance
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        width: "150px",
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        width: "200px",
        options: quartersArray,
        defaultValue: `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: "All Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setCompliance(false);
          setMarket("All Markets");
          setYear(getCurrentYear());
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };


  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Issues And Programs",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Issues And Programs"]
      }} />
      <TableCellModalButtonV2 {...{modal: {modalForm: FccIssuesAndProgramsForm}}} />
      <ButtonBar {...buttonBarButons} />
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        tableKey: "issuesTable",
        mobile: props.mobile,
        data: data?.items && data?.items.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : columns,
        loading: isPending,
        error: isError,
        errorMessage: errorState
      }} />
    </Suspense>
  );
};