import React, {lazy, useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ModalFooter from "components/ui/modal/components/ModalFooter";
import MultiSelect from "components/ui/forms/components/fields/MultiSelect";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useMainSnackBar} from "hooks/useMainSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import {allTheFacilitiesData, allTheMarketsData} from "services/jotai/requests/requests";
import {useCallsignConverter} from "hooks/useCallsignConverter";
import {cachedRequests} from "services/api/cachedRequests";
import urlPaths from "services/api/urlPaths";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Button} from "@mui/material";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {Brand} from "../table/components/cell/Brand";
import {bulkUploadCsvIds} from "services/jotai/political/political";
import {CollapseTable} from "../table/collapseTable";
import {v4 as uuidv4} from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {settings} from "services/jotai/settings/settings";
import TextField from "./components/fields/TextField";
import CheckBox from "./components/fields/Checkbox";
import {useAtomWithQuery} from "../../../hooks/useAtomWithQuery";


interface IFormInputs {
  Markets: any | string | string[],
  Callsigns: string[],
  PdfFile: any,
  OrderType: "",
  OrderSearch: "",
  OrderMatches: [],
  OrderSearchButton: boolean,
  OrderShowLatest: boolean,
  OrderYear: string,
  OrderQuarter: string,
  Matches: any[],
  Order: any,
  File: any,
  Files: any[],
  Type: string,
  OfficeType: string,
  Office: string,
  Years: number | null,
  Quarters: any,
  CandidateOrIssueName: string,
  CustomFolder: string,
  CustomFolders: string[],
  FilePath: string[],
  ExistingFolders: string
}

interface Market {
  name: string;
  id: string | number;
}

interface MarketOutput {
  label: string;
  id: string | number;
  facility_id?: string | number;
}

const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]})));

const getMarkets = (allMarketsData: Market[]): MarketOutput[] => {
  const allMarkets: Set<MarketOutput> = new Set();

  allMarketsData?.forEach((market: Market) => {
    allMarkets.add({label: market.name, id: market.name});
  });

  return Array.from(allMarkets);
};
const getCallsigns = (selectedMarkets: any, tsMarkets: any) => {
  const allCallsigns: Set<any> = new Set([]);
  if (tsMarkets && selectedMarkets && selectedMarkets.length > 0) {
    if (typeof selectedMarkets === "string") {
      tsMarkets?.forEach((tsMarket: any) => {
        if (tsMarket["name"].toLowerCase() === selectedMarkets.toLowerCase()) {
          tsMarket.brands.forEach((brands: any) => {
            allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
          });
        }
      });
      return Array.from(allCallsigns);
    } else {
      tsMarkets?.forEach((tsMarket: any) => {
        selectedMarkets.forEach((selectedMarket: any) => {
          if (tsMarket["name"].toLowerCase() === selectedMarket.toLowerCase()) {
            tsMarket.brands.forEach((brands: any) => {
              allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
            });
          }
        });
      });
      return Array.from(allCallsigns);
    }
  } else {
    return [];
  }
};

export default function FccPoliticalForcedMatching(props: any) {
  const
    {requestAtom} = useAtomWithQuery(),
    {makeRequest} = useApiAuth(),
    [allMarketsData] = useAtom(allTheMarketsData),
    [globalSettings] = useAtom<any>(settings),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {
      getCurrentQuarter,
      getArrayOfQuarters
    } = useQuarter(),
    navigate = useNavigate(),
    [bulkCsvIds] = useAtom(bulkUploadCsvIds),
    {
      getCurrentYear,
      getArrayOfYears,
      convertDatePretty
    } = useDate(),
    {cachedMarketsData} = cachedRequests(),
    {getCallsign} = useCallsignConverter(),
    [allFacilitiesData]: any = useAtom(allTheFacilitiesData),
    [resetPagination, setResetPagination] = useState(0),
    [submitted, setSubmitted] = useState(false),
    {makePoliticalFileUploadRequest} = useApiAuth(),
    {register, resetField, handleSubmit, control, setValue: parentSetValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        OrderType: "",
        OrderSearch: "",
        OrderMatches: [],
        OrderSearchButton: false,
        OrderShowLatest: true,
        OrderYear: "",
        OrderQuarter: "",
        Order: {},
        Markets: [],
        Callsigns: [],
        Matches: [],
        File: {},
        Files: [],
        Years: getCurrentYear(),
        Quarters: `Q${getCurrentQuarter()}`,
        ExistingFolders: "Existing FCC Folders"
      },
    }),
    year: any = useWatch({control, name: "Years"}),
    matches = useWatch<any>({control, name: "Matches"}),
    files = useWatch<any>({control, name: "Files"}),
    market = useWatch({control, name: "Markets"}),
    callsigns = useWatch({control, name: "Callsigns"}),
    [marketCallsigns, setMarketCallsigns] = useState([]),
    [marketNames, setMarketNames] = useState<any>([]),
    yearOptions = getArrayOfYears(),
    {setMainSnackBar} = useMainSnackBar(),
    [newCallsigns, setNewCallsigns] = useState<any>([]),
    passesValidation = () => {
      if (Object.keys(files).length > 0) {
        const allValid = Object.keys(files).every((fileId: any) => {
          console.log("validation", files[fileId].validates);
          return files[fileId].validates === true;
        });
        return allValid;
      } else {
        return false;
      }
    },
    resolver = async (data: any) => {
      const allValid = passesValidation();
      console.log("validation resolver", allValid);

      if (allValid) {
        const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

        console.log("submit data", data.data, data.files);
        const batchId = uuidv4().toString().replace(/-/gi, "");
        setSubmitted(true);
        let filesSubmitted = 0;
        await Object.keys(data.files).map(async (csv_id: any) => {
          console.log("csv_id", csv_id, files[csv_id].facilityId);
          let index = 0;
          if (data.files[csv_id].cleanFiles) {
            for await (const file of data.files[csv_id].cleanFiles) {
              await console.log("submit data cleanFile", {
                status: file.status,
                file: file?.file,
                csv: files[csv_id],
                data: data.files[csv_id]
              });
              const filePath = data.files[csv_id].filePath && data.files[csv_id].customFolders ? data.files[csv_id].filePath.join("/").toString() + "/" + data.files[csv_id].customFolders.join("/").toString() : data.files[csv_id].filePath ? data.files[csv_id].filePath.join("/").toString() : null;
              const correctedFilePath = globalSettings?.enviornment?.env?.value === "development" ? filePath.replace(data.Years.toString(), "2014") : filePath;
              // Add the new document to the "craft_fccpoliticalissues" collection
              await makePoliticalFileUploadRequest("POST", urlPaths.POLITICAL_DOCUMENT_UPLOAD, {
                ui_batch_id: batchId,
                fcc_path: correctedFilePath,
                year: globalSettings?.enviornment?.env?.value === "development" ? "2014" : year?.toString(),
                candidate_campaign_name: data.files[csv_id].candidateOrIssueName ? data.files[csv_id].candidateOrIssueName.includes("/") ? data.files[csv_id].candidateOrIssueName.split("/")[0] : data.files[csv_id].candidateOrIssueName : String("Terms and Disclosures"),
                facility_id: data.files[csv_id].facilityId,
                file: file?.file,
                candidate_type: data.files[csv_id].type,
                region: data.files[csv_id].officeType ? data.files[csv_id].officeType : "",
                office_name: data.files[csv_id].office ? data.files[csv_id].office : "",
                custom_folder_path: data.files[csv_id].customFolders ? data.files[csv_id].customFolders : [],
              }, null).then(async (response: any) => {
                if (response) {
                  parentSetValue(`Files.${csv_id}.cleanFiles[${index}].status`, "complete", {shouldValidate: true});
                  index++;
                  filesSubmitted++;
                  if (response?.items[0]?.file_id) {
                    const resp = response?.items[0];
                    // Add the new array item to the existing possibleMatches array
                    await makeRequest("POST", urlPaths.POLITICAL_MATCHES_ADD, {
                      file_id: resp.file_id,
                      csv_id: csv_id,
                      username: userInfo.username,
                      facility_id: resp.facility_id,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      callsign: allFacilitiesData[resp?.facility_id]?.callsign,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      market_name: allFacilitiesData[resp?.facility_id]?.market,
                      name: file?.file?.name,
                      candidate_campaign_name: data.files[csv_id].candidateOrIssueName.toString(), /* data.CandidateOrIssueName.toString(), */
                      year: resp.year,
                      quarter: Number(resp.date_based_quarter),
                      date_based_quarter: Number(resp.date_based_quarter),
                      path: data.files[csv_id].filePath.join("/").toString(),
                    }, null).then((data: any) => {
                      console.log("Adding a new match", data);
                    }).catch((error) => {
                      console.log("error", error);
                    });
                    await sleep(1000);
                    // Add the new document to the political matches collection craft_fccpoliticalissuesmatched
                    await makeRequest("POST", urlPaths.POLITICAL_MATCHES_SET, null, {
                      csv_id: csv_id,
                      file_id: resp.file_id,
                      username: userInfo.username,
                      method: "upload",
                      form: "bulk"
                    }).catch((error) => {
                      console.log("error", error);
                    });
                  }
                }
              }).catch((error: any) => {
                setMainSnackBar("error", `Error adding document: (${file?.file?.name})`, "error");
                console.log("error", error);
              });
            }
          }
        });
        await sleep(4000);
        await setMainSnackBar("success", `${filesSubmitted} new document successfully submitted!`, "success");
        await navigate(`/political-candidates-and-issues/queue/${batchId}`);
      } else {
        setMainSnackBar("error", `Please ensure images have been added to each order and that upload details have been set before proceeding`, "error");
      }
    },
    onSubmit: SubmitHandler<IFormInputs> = (data: any) => resolver({data: data, files: files}),
    [allBrandsData, setAllBrandsData] = useState<any>([]),
    getCsvData = () => {
      if (bulkCsvIds && bulkCsvIds.length >= 0) {
        let ids;
        if (bulkCsvIds.length >= 1) {
          ids = bulkCsvIds.map((item: any, index: number) => `csv_ids[${index}]=${encodeURIComponent(item)}`).join("&");
        } else {
          ids = bulkCsvIds.map((item: any, index: number) => `csv_ids[${index}]=${encodeURIComponent(item)}`);
        }
        return makeRequest("GET", `${urlPaths.POLITICAL_MATCHES_BY_IDS}?${ids}`, null, null).then(async (response: any) => {
          if (response) {
            // await setAllCsvData(response.items);
            return response.items;
          }
        }).catch((error: any) => {
          return error;
        });
      }
      return null;
    };

  useEffect(() => {
    setResetPagination(resetPagination + 1);
  }, []);

  // Populate data once the API returns the rest markets data
  useEffect(() => {
    cachedMarketsData();
  }, []);

  // // Redirect the user after 3 seconds if there are no bulkCsvIds
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (bulkCsvIds.length <= 0) {
  //       navigate("/political-candidates-and-issues/matching/");
  //     }
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, [bulkCsvIds]);

  // Update files data when new callsigns are selected
  useEffect(() => {
    if (allBrandsData) {
      const data: any = {};
      allBrandsData.map((item: any) => {
        data[item.facility_id] = {
          OrderType: "",
          OrderSearch: "",
          OrderMatches: [],
          OrderSearchButton: false,
          OrderShowLatest: true,
          OrderYear: getCurrentYear(),
          OrderQuarter: `Q${getCurrentQuarter()}`,
          Order: {},
          file_type: "",
          file_search: "",
          file_matches: [],
          file: {},
          ...item
        };
      });

      parentSetValue("Matches", data);
    }
  }, [allBrandsData]);

  // Set the market names inside the select
  useEffect(() => {
    if (allMarketsData) {
      const allTsmMarkets: MarketOutput[] = getMarkets(allMarketsData);
      setMarketNames(allTsmMarkets);
    }
  }, [allMarketsData]);

  // Wipe callsign selections and repopulate the select options with new callsigns each time the markets dropdown changes
  useEffect(() => {
    parentSetValue("Callsigns", []);
    const allCallSigns: any = getCallsigns(market, allMarketsData);
    setMarketCallsigns(allCallSigns);
  }, [market]);

  useEffect(() => {
    setNewCallsigns(callsigns);
    const brandData: any = [];
    callsigns.map((callsign: any) => {
      if (allFacilitiesData) {
        brandData.push(
          allFacilitiesData[callsign]
        );
      }
    });
    setAllBrandsData(brandData);
    console.log("allBrandData", brandData);
  }, [callsigns]);

  // useEffect(() => {
  //   if (bulkCsvIds && bulkCsvIds.length > 0) {
  //     getCsvData()?.then((resp: any) => {
  //       console.log("resp", resp);
  //       const allMarkets = new Set();
  //       const allCallsigns = new Set();
  //       resp.map((item: any) => {
  //         allMarkets.add({label: item.fcc_markets.market, id: item.fcc_markets.market});
  //         allCallsigns.add({
  //           label: item.fcc_markets.calls,
  //           id: item.fcc_markets.calls,
  //           facility_id: item.fcc_markets.facId,
  //         });
  //       });
  //       setMarketNames(Array.from(allMarkets));
  //       parentSetValue("Markets", Array.from(allMarkets).map((market: any) => market.id));
  //       setNewCallsigns(Array.from(allCallsigns));
  //       parentSetValue("Callsigns", Array.from(allCallsigns).map((callsign: any) => callsign.facility_id));
  //
  //
  //       setAllCsvData(resp);
  //     }).catch(console.error);
  //   }
  // }, [bulkCsvIds]);

  // Add csv_ids to each of the items inside the files object
  // useEffect(() => {
  //   if (files) {
  //     allCsvData.map((csv: any) => {
  //       const temp = files;
  //       if (temp[csv.csv_id]?.csv_id) {
  //         temp[csv.csv_id].csv_id = csv.csv_id;
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         parentSetValue(`Files`, temp, {shouldValidate: true});
  //       }
  //     });
  //     console.log("allCsvData", allCsvData);
  //   }
  // }, [allCsvData]);

  useEffect(() => {
    if (props.submit) {
      console.log("trying to submit", props.submit);
      trigger().then((isValid) => {
        if (isValid) {
          handleSubmit(onSubmit)(); // Submits the form if all validations pass
        }
      });
    }
  }, [props.submit]);

  useEffect(() => {
    let count = 0;
    if (files && Object.keys(files)?.length > 0) {
      Object.keys(files)?.forEach((facility: any) => {
        if (files[facility].files) {
          count += files[facility].files?.length;
        }
      });
      // setFileCount(count);
    }
    console.log("files", files);
  }, [files]);

  const SelectAnOrder = (p: any) => {
    const {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
          // order_type: null,
          // order_search: null,
          // order_matches: [],
          // order: {},
          // file_type: null,
          // file_search: null,
          // file_matches: [],
          // file: {},
        OrderShowLatest: matches[p.props.facility_id].OrderShowLatest,
        OrderYear: matches[p.props.facility_id].OrderYear,
        OrderQuarter: matches[p.props.facility_id].OrderQuarter,
        OrderSearchButton: matches[p.props.facility_id].OrderSearchButton,
        OrderType: matches[p.props.facility_id].OrderType,
        OrderSearch: matches[p.props.facility_id].OrderSearch,
        OrderMatches: matches[p.props.facility_id].OrderMatches,
        Order: matches[p.props.facility_id].order,
        File: matches[p.props.facility_id].file,
      },
    }),
      order_year = useWatch({control, name: "OrderYear"}),
      order_quarter = useWatch({control, name: "OrderQuarter"}),
      order_type = useWatch({control, name: "OrderType"}),
      order_search = useWatch({control, name: "OrderSearch"}),
      order_search_button = useWatch({control, name: "OrderSearchButton"}),
      order_latest = useWatch({control, name: "OrderShowLatest"}),
      [errorState, setErrorState] = useState(null),
      complianceAtom = requestAtom("politicalMatching", urlPaths.POLITICAL_MATCHES, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        year: !order_latest ? order_year : undefined,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        market: [p.props.market], /*market,*/
        facility_id: [p.props.facility_id],
        quarter: !order_latest ? order_quarter : undefined,
        showLatest: order_latest ? order_latest : undefined,
        // matched: undefined,
        search: order_search ? order_search : undefined,
      }, setErrorState),
      [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
      [{data, isPending, isError}] = useAtom<AtomQuery>(complianceState),
      temp = matches;

    useEffect(() => {
      matches[p.props.facility_id].OrderType = order_type;
      matches[p.props.facility_id].OrderSearch = order_search;
      matches[p.props.facility_id].OrderShowLatest = order_latest;
      matches[p.props.facility_id].OrderSearchButton = order_search_button;

      if (order_type?.toString() === "Most Recent") {
        console.log("get data");
      }
      setComplianceState(complianceAtom);
    }, [order_year, order_quarter, order_type, order_latest, order_search_button]);

    useEffect(() => {
      console.log("ppp", p);
    }, [p]);

    return <Stack direction={"column"} spacing={2}>
      <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
        <MultiSelect {...{
          name: "OrderType",
          label: "Type",
          multiSelect: false,
          validationMessage: "Select a type",
          options: ["Most Recent", "Order ID", "Search Term"],
          required: true,
          control: control
        }}  />
        <CheckBox {...{
          name: "OrderShowLatest",
          label: "Past 30 Days?",
          // validationMessage: "Tell us something",
          required: false,
          // handleChange: updateTextField,
          control: control
        }} />

        {!order_latest ? <MultiSelect {...{
          name: "OrderYear",
          label: "Year",
          multiSelect: false,
          validationMessage: "Select a year",
          options: getArrayOfYears(),
          required: true,
          control: control
        }}  /> : <></>}

        {!order_latest ? <MultiSelect {...{
          name: "OrderQuarter",
          label: "Quarter",
          multiSelect: false,
          validationMessage: "Select a year",
          options: getArrayOfQuarters(Number(order_year)),
          required: true,
          control: control
        }}  /> : <></>}

        {order_type && order_type !== "Most Recent" ? <TextField {...{
          name: "OrderSearch",
          label: `Search`,
          validationMessage: `Please enter a search term`,
          required: false,
          spacesAllowed: true,
          specialCharactersAllowed: true,
          control: control
        }} /> : <></>}
        {order_type && order_type !== "Most Recent" ? <Button variant="contained" disableElevation size={"small"}
          onClick={() => {
                                                                // if (single_custom_folder !== "") {
            setValue("OrderSearchButton", true);
                                                                //   register("CustomFolder");
                                                                //   resetField("CustomFolder", {defaultValue: ""});
                                                                // }
            console.log("p.props", matches[p.props.facility_id]);
                                                                // matches[p.props.facility_id].OrderSearchButton = true;
                                                                // matches[p.props.facility_id].OrderSearchButton = true;
                                                                // parentSetValue("Matches", [...matches, matches[p.props.facility_id].order_search_button: true]);
          }}
          sx={{
            backgroundColor: "rgb(162 162 162)",
            color: "white"
          }}>Search</Button> : <></>}
      </Stack>
      {String(order_search).length > 0 && order_search_button === true || order_type && order_type === "Most Recent" ?
        <Stack direction={"column"} spacing={2}>
          <MultiSelect {...{
            name: "Order",
            label: "Select an order",
            multiSelect: false,
            validationMessage: "Select an order",
            options: data?.items.map((item: any) => `${item.advertiser} | ${item.order_number} | ${item.estimate} | ${item.flight_start} - ${item.flight_end}`), /*"Terms and Disclosures"*/
            required: true,
            control: control
          }}  />
        </Stack> : <></>}
      {String(order_search).length > 0 && order_search_button === true || order_type && order_type === "Most Recent" ?
        <Stack direction={"column"} spacing={2}>
          <MultiSelect {...{
            name: "Order",
            label: "Select an order",
            multiSelect: false,
            validationMessage: "Select an order",
            options: data?.items.map((item: any) => `${item.advertiser} | ${item.order_number} | ${item.estimate} | ${item.flight_start} - ${item.flight_end}`), /*"Terms and Disclosures"*/
            required: true,
            control: control
          }}  />
        </Stack> : <></>}
    </Stack>;
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const SubTableRowComponent = (p: { props: any }) => {
    const accordion: { name: string, title: string, disabled: boolean, expanded: boolean, component: any }[] = [{
      name: "orders",
      title: "Select An Order",
      expanded: false,
      disabled: false,
      component: <SelectAnOrder {...p} />
    }, {
      name: "matching",
      title: "Select A File",
      expanded: false,
      disabled: false,
      component: <SelectAnOrder {...p} />
    }];

    return p ? accordion.map((item: {
      name: string,
      title: string,
      disabled: boolean,
      expanded: boolean,
      component: any
    }) => <Accordion
      key={uuidv4()}
      expanded={item.expanded ? item.expanded : expanded === item.name}
      onChange={handleChange(item.name)}
      disabled={item.disabled}
      sx={{
        // Styles applied to the Accordion root element
        backgroundColor: "#f9f9f9", // Default background color
        "&.Mui-expanded": {
          // Styles applied when the Accordion is expanded
          backgroundColor: "#e0f7fa", // Change background color when expanded
          // Add any other styles you want for the expanded state here
        },
        // "&.Mui-disabled": {
        //   // Styles applied when the Accordion is expanded
        //   backgroundColor: "#f5f5f5", // Change background color when expanded
        //   // Add any other styles you want for the expanded state here
        // },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={item.name}
        id={item.name}>{item.title}</AccordionSummary>
      <AccordionDetails>
        <div onClick={(event) => event.stopPropagation()}>
          {item.component}
        </div>
      </AccordionDetails>
    </Accordion>) : <></>;
  };

  const collapse_columns = [{
    field: "market",
    id: "market",
    headerName: "Market",
    flex: 1,
    renderCell: (params: { market: string, facility_id: string }) => {
      return params?.market;
      // return (<Link
      //   to={`/market/${getCallsign(params?.row.facility_id)?.market}/`}>{getCallsign(params?.row.facility_id)?.market}</Link>);
    }
  }, {
    field: "brand",
    id: "brand",
    headerName: "Brand",
    maxWidth: "60px",
    align: "left",
    sortable: false,
    editable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: { callsign: string, facility_id: string, market: string, service: string }) =>
      <Brand {...{
        callsign: params?.callsign,
        facility_id: params?.facility_id,
        service: params?.service
      }} />
  }, {
    field: "callsign",
    id: "callsign",
    headerName: "Call Sign",
    maxWidth: "120px",
    renderCell: (params: { callsign: string, facility_id: string, market: string, service: string }) =>
      <Link to={`/facility/${params.facility_id}/`}>{params.callsign}</Link>
  }
    // , {
    //   field: "date_created",
    //   id: "date_created",
    //   headerName: "Date Created",
    //   maxWidth: "100px",
    //   renderCell: (params: { date_created: string }) => {
    //     return convertDatePretty(params?.date_created);
    //   },
    // }, {
    //   field: "flight_start",
    //   id: "flight_start",
    //   headerName: "Flight Start",
    //   maxWidth: "100px",
    //   renderCell: (params: { flight_start: string }) => {
    //     return convertDatePretty(params?.flight_start);
    //   },
    // }, {
    //   field: "flight_end",
    //   id: "flight_end",
    //   headerName: "Flight End",
    //   maxWidth: "90px",
    //   renderCell: (params: { flight_end: string }) => {
    //     return convertDatePretty(params?.flight_end);
    //   },
    // }, {
    //   field: "order_number",
    //   id: "order_number",
    //   headerName: "WO Order #",
    //   maxWidth: "100px",
    //   renderCell: (params: { order_number: string }) => {
    //     return params?.order_number;
    //   }
    // }, {
    //   field: "advertiser",
    //   id: "advertiser",
    //   headerName: "Advertiser",
    //   renderCell: (params: { advertiser: string }) => {
    //     return params?.advertiser;
    //   }
    // }, {
    //   field: "files",
    //   id: "files",
    //   headerName: "Add Files",
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (params: {
    //     id: string,
    //     csv_id: string,
    //     fcc_markets: { calls: string, facId: string, service: string }
    //   }) => {
    //     return <MediaUploaderDropZone
    //       name={`files.${params.csv_id}.files`}
    //       control={control}
    //       validationMessage={"Select at least one document for upload"}
    //       mediaType={{
    //         "application/pdf": [".pdf"],
    //         "application/msword": [".doc"],
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    //         "application/rtf": [".rtf"],
    //         "application/vnd.ms-excel": [".xls"],
    //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    //         "text/plain": [".txt"],
    //       }}
    //       message={"Click to choose files or drag and drop"}
    //       required={true}
    //       width={"350px"}
    //       fileCount={files[params.csv_id]?.files?.length}
    //       onFileChange={async (file: any) => {
    //         // Logic to append new files to the uploads data
    //         const newVals: any = [];
    //         const cleanFiles: any = [];
    //         newVals.push(...file);
    //
    //         file.map((f: any) => {
    //           cleanFiles.push({file: f, status: null, id: f.lastModified});
    //         });
    //
    //         if (files[params.csv_id]?.files?.length > 0) {
    //           newVals.push(...files[params.csv_id].files);
    //           cleanFiles.push(...files[params.csv_id].cleanFiles);
    //         }
    //
    //         const data = files[params.csv_id];
    //         data.files = newVals;
    //         data.cleanFiles = cleanFiles;
    //
    //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //         // @ts-ignore
    //         await parentSetValue(`Files.${params.csv_id}`, data, {shouldValidate: true});
    //         // await setValue(`Files.${params.fcc_markets.facId}.files`, newVals, {shouldValidate: true});
    //
    //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //         // @ts-ignore
    //         // await setValue(`Files.${params.fcc_markets.facId}.cleanFiles`, cleanFiles, {shouldValidate: true});
    //       }}
    //     />;
    //   }
    // }
  , {
    field: "validates",
    id: "validates",
    headerName: "Status",
    renderCell: (params: { csv_id: string, fcc_markets: { calls: string, facId: string, service: string } }) => {
      return files[params.csv_id]?.cleanFiles === null ? "Add Documents" : files[params.csv_id]?.validates === null || files[params.csv_id]?.validates === false ? "Add Upload Details" : files[params.csv_id]?.validates === true ? "Complete" : null;
    }
  }];

  return (
    <form onSubmit={handleSubmit(onSubmit)} name={"political_candidates_and_issues_bulk"}>
      <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 2}} sx={{padding: "20px"}}>
        <Grid item xs={12}>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Years",
              label: "Year",
              multiSelect: false,
              validationMessage: "Select a year",
              options: yearOptions,
              required: true,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Markets",
              label: "Markets",
              multiSelect: true,
              validationMessage: "Select at least one market",
              options: marketNames && marketNames.length > 0 ? marketNames : [],
              disabled: false,
              required: false,
              handleChange: onSubmit,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Callsigns",
              label: "Callsigns",
              multiSelect: true,
              validationMessage: "Select at least one callsign",
              options: marketCallsigns && marketCallsigns.length > 0 ? marketCallsigns : [],
              disabled: false,
              required: false,
              handleChange: onSubmit,
              control: control
            }}  />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Document Upload:</strong></p>
          <CollapseTable {...{
            data: allBrandsData?.length > 0 ? allBrandsData : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: props.mobile ? collapse_columns : collapse_columns,
            pending: false,
            tableKey: "politicalMatchingTablev2",
            mobile: props.mobile,
            resetPagination: resetPagination,
            // tableSortBy: "order_number",
            // subTableColumns: columns,
            subTableComponent: SelectAnOrder,
            subTableKey: "possibleMatchesv2",
            // subTableSortBy: "score",
            loading: false,
            error: false,
            errorMessage: ""
          }} />
        </Grid>
      </Grid>
      <ModalFooter {...{
        buttons: {
          left: [],
          right: [{
            variant: "contained",
            color: null,
            name: "Upload To FCC",
            type: "submit",
          }]
        },
        close: props.handleChange,
        submitButtonState: marketCallsigns.length === 0 && submitted !== false ? true : false
      }} />
    </form>
  );
}