import React from "react";
import {useSnackbar} from "notistack";

export const useSnackBar = () => {
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    console.log("handleClose");
  };

  const setSnackBar = (message: string, severity: any) => {
    try {
      enqueueSnackbar(message, {
        className: severity ? `signalcraft-form-${severity}` : undefined,
        variant: severity,
        autoHideDuration: 94000,
        onClose: () => handleClose(),
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {setSnackBar};
};