import React, {lazy, Suspense, useState} from "react";
import FccPoliticalForcedMatching from "components/ui/forms/political_manual_forced_matching";
import {TableCellModalButtonV2} from "components/ui/table/components/cell/ModalV2";
import Paper from "@mui/material/Paper";


const
  Title = lazy(() => import("components/ui/pagetitle").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb").then((module) => ({default: module["Breadcrumb"]}))),
  ButtonBar = lazy(() => import("components/ui/buttonbar")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccPoliticalBulkForcedMatching = (props: any) => {
  const [submit, setSubmit] = useState(false);

  const buttonBarButons: any = {
    mobile: props.screen,
    left: [],
    right: [{
      name: "Share Feedback",
      type: "link",
      location: "external",
      href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/upload/"
    }]
  },
    topperProps: any = {
      title: `Political Document Uploads`,
      inputs: [{
        label: "Upload To FCC",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setSubmit(true);
          setTimeout(() => {
            setSubmit(false);
          }, 5000);
        })
      }]
    };

  return (<Suspense fallback={<></>}>
    <Title {...{
      title: "Political Document Uploads",
      video: {
        title: "Document Uploader",
        src: "/craft/rest/training/Political-MultiSelectUploader.mp4"
      },
      mobile: props.mobile,
    }} />
    <Breadcrumb {...{
      breadcrumb: ["Home", "Political Candidates & Issues"]
    }} />
    <TableCellModalButtonV2 {...{modal: {modalForm: FccPoliticalForcedMatching}}} />
    <ButtonBar {...buttonBarButons} />
    <Topper {...{...topperProps, mobile: props.mobile}} />
    <Paper
      sx={{
        marginTop: "25px",
        borderRadius: "0px",
        margin: props?.mobile ? "25px 10px" : undefined
      }}
      elevation={props?.mobile ? 3 : 4}>
      <FccPoliticalForcedMatching {...{
        mobile: props?.mobile,
        submit: submit
      }} />
    </Paper>
  </Suspense>);
};