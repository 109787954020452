import React, {lazy, Suspense} from "react";
import {TableCellModalButtonV2} from "components/ui/table/components/cell/ModalV2";
import {FccPoliticalFullReportTable} from "pages/fcc_political_candidates_and_issues/components/tables/full_report";
// import {
//   FccPoliticalTermsTable
// } from "pages/fcc_political_candidates_and_issues/components/tables/terms_and_disclosures";
import FccPoliticalFetchNewFccDocuments from "components/ui/forms/political_fetch_new_fcc_documents";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Title = lazy(() => import("components/ui/pagetitle").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb").then((module) => ({default: module["Breadcrumb"]}))),
  FccPoliticalCandidatesAndIssuesForm = lazy(() => import("components/ui/forms/political_candidates_and_issues")),
  ButtonBar = lazy(() => import("components/ui/buttonbar"));
export const FccPoliticalTermsAndDisclosures = (props: any) => {
  const
    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload Terms & Disclosures",
        type: "link",
        location: "internal",
        href: "/political-candidates-and-issues/terms-and-disclosures-uploader/",
        // disabled: bulkUploadDocs.length > 0 ? false : true
      }, {
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/report/"
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Candidates & Issues",
        video: {
          title: "Terms & Disclosures",
          src: "/craft/rest/training/Political-TermsAndDisclosures.mp4"
        },
        mobile: props.mobile,
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Political Candidates & Issues/", "Terms & Disclosures"]
      }} />
      <TableCellModalButtonV2 {...{modal: {modalForm: FccPoliticalCandidatesAndIssuesForm}}} />
      <ButtonBar {...buttonBarButons} />
      <FccPoliticalFullReportTable {...{...props, showFccLink: false, terms: true}} />
    </Suspense>
  );
};