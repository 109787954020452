import React, {lazy, Suspense} from "react";
import styled from "@emotion/styled";
import {NavLink as Link} from "react-router-dom";

const
  Typography = lazy(() => import("@mui/material/Typography")),
  Skeleton = lazy(() => import("@mui/material/Skeleton")),
  Button = lazy(() => import("@mui/material/Button")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Avatar = lazy(() => import("@mui/material/Avatar")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder"));

export default function FacilityDetails(props: any) {
  const
    Line = styled(Typography)`
      margin: 0px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 11px;
    `;
  const stringAvatar = (callsign: string) => {
    return {
      sx: {
        height: "110px",
        width: "110px",
        fontSize: "18px",
      },
      children: `${callsign}`,
    };
  };
  return (
    <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={250}/>}>
      <Stack direction="row" spacing={3}>
        <Stack direction="column" spacing={0}>
          <Avatar
            alt={`${props.callsign} Logo`}
            {...stringAvatar(`${props.callsign}`)}
            src={`/craft/rest/image?facility_id=${props?.facility_id}`}
            sx={{
              width: 110,
              height: 110,
              fontSize: "13px",
              fontColor: "white",
              backgroundColor: "#010159",
              boxShadow: 3
            }}
          />
        </Stack>
        <Stack direction="column" spacing={0}>
          <Line variant={"h6"}><strong>Callsign:</strong> {props.callsign}</Line>
          <Line variant={"h6"}><strong>Market:</strong> <Link
            to={`/market/${props.market}/`}>{props.market}</Link></Line>
          <Line variant={"h6"}><strong>Service:</strong> {props.service}</Line>
          <Line variant={"h6"}><strong>Facility ID:</strong> {props.facility_id}</Line>
          <Line>
            <Button
              variant="contained"
              href={props.fcc_directory}
              sx={{boxShadow: 0, marginTop: "4px", backgroundColor: "#00bcd4"}}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target={"_blank"}>
              <FolderIcon/><span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC DIRECTORY</span>
            </Button>
          </Line>
        </Stack>
      </Stack>
    </Suspense>
  );
}