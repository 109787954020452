import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {useSnackBar} from "hooks/useSnackBar";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {bulkUploadCsvIds, screenTopperSettings} from "services/jotai/political/political";
import {Brand} from "components/ui/table/components/cell/Brand";
import CircleIcon from "@mui/icons-material/Circle";
import {v4 as uuidv4} from "uuid";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FccPoliticalFetchNewFccDocuments from "components/ui/forms/political_fetch_new_fcc_documents";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Status = lazy(() => import("components/ui/table/components/cell/Status").then((module: any) => ({default: module["Status"]}))),
  Checkbox = lazy(() => import("@mui/material/Checkbox")),
  Tooltip = lazy(() => import("@mui/material/Tooltip")),
  TextField = lazy(() => import("@mui/material/TextField")),
  FormControl = lazy(() => import("@mui/material/FormControl")),
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Button = lazy(() => import("@mui/material/Button")),
  UploadFileIcon = lazy(() => import("@mui/icons-material/UploadFile")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  CollapseTable = lazy(() => import("components/ui/table/collapseTable").then((module) => ({default: module["CollapseTable"]}))),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

interface Matches {
  matched: boolean;
  row: {
    matched: boolean,
    csv_id: string,
    date_approved: string,
    date_created: string,
    estimate: string,
    advertiser: string,
    advertiser_orig: string,
    ae_name: string,
    market: string,
    flight_start: string,
    flight_end: string,
    order_type: string,
    order_number: string,
    broadcast_type: string,
    callsign: string,
    callletters: string,
    service: string,
    date: string,
    fcc_markets: { facId: string, calls: string, market: string, service: string, fccDirUrl: string },
    possibleMatches: {
      file_id: string,
      fcc_file_id: string,
      facility_id: string,
      market: string,
      callsign: string,
      service: string,
      name: string,
      link: string,
      containing_folder_link: string,
      path: string,
      upload_date: string,
      modifiedDate: string,
      score: number
    }[]
  }
  estimate: string,
  csv_id: string,
  date_approved?: string,
  date_created: string,
  advertiser?: string,
  advertiser_orig?: string,
  ae_name?: string,
  market?: string,
  flight_start: string,
  flight_end: string,
  order_type: string,
  order_number?: string,
  broadcast_type?: string,
  callsign: string,
  callletters?: string,
  service?: string,
  date?: string,
  fcc_markets: { facId: string, calls: string, market: string, service: string, fccDirUrl: string },
  possibleMatches?: {
    file_id: string,
    fcc_file_id: string,
    facility_id: string,
    market: string,
    callsign: string,
    service: string,
    name: string,
    link: string,
    containing_folder_link: string,
    path: string,
    upload_date: string,
    modifiedDate: string,
    score: number
  }[]
}

export const FccPoliticalMatching = (props: any) => {
  const
    navigate = useNavigate(),
    [bulkCsvIds, setBulkCsvIds] = useAtom(bulkUploadCsvIds),
    [topperSettings, setScreenTopperSettings]: any = useAtom<any>(screenTopperSettings),
    [search, setSearch] = useState<string>(""),
    handleSearchChange = (event: any) => {
      setSearch(event.target.value);
    },
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {makeRequest} = useApiAuth(),
    {setSnackBar} = useSnackBar(),
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    {
      getCurrentQuarter,
      getArrayOfQuarters
    } = useQuarter(),
    {convertDate, convertDatePretty, getCurrentYear, getArrayOfYears, daysBetweenDates} = useDate(),
    [resetPagination, setResetPagination] = useState(0),
    [matched, setMatched] = useState<any>(topperSettings?.matching?.matched ? topperSettings.matching.matched : false),
    [showLatest, setShowLatest] = useState<any>(topperSettings?.matching?.showLatest ? topperSettings.matching.showLatest : true),
    [advTableFilterOption, setAdvTableFilterOption] = useState<string>(topperSettings?.matching?.advTableFilterOption ? topperSettings.matching.advTableFilterOption : "Show All"),
    [showAll, setShowAll] = useState<any>(topperSettings?.matching?.showAll ? topperSettings.matching.showAll : true),
    [year, setYear] = useState<any>(topperSettings?.matching?.year ? topperSettings.matching.year : getCurrentYear()),
    [quarter, setQuarter] = useState<any>(topperSettings?.matching?.quarter ? topperSettings.matching.quarter : `Q${getCurrentQuarter()}`),
    [market, setMarket] = useState(topperSettings?.matching?.market ? topperSettings.matching.market : "My Markets"),
    [bulkUploadDocs, setBulkUploadDocs] = useState<any>([]),


    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    complianceAtom = requestAtom("politicalMatching", urlPaths.POLITICAL_MATCHES, {
      year: !showLatest ? year : undefined,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      market: market === "My Markets" ? allUserMarkets : [market], /*market,*/
      quarter: !showLatest ? quarter : undefined,
      showLatest: showLatest ? showLatest : undefined,
      matched: advTableFilterOption === "Show All" ? undefined : advTableFilterOption === "Show Completed" ? "true" : advTableFilterOption === "Show Pending" ? "false" : undefined,
      search: search ? search : undefined,
      showAll: advTableFilterOption === "Show All" ? true : undefined
    }, setErrorState),
    [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(complianceState);


  useEffect(() => {
    console.log("bulkUploadDocs", bulkUploadDocs);
    setBulkCsvIds(bulkUploadDocs);
  }, [bulkUploadDocs]);

  // Get the markets data once from local storage or the server
  useEffect(() => {
    setBulkCsvIds([]);
    cachedMarketsData();
  }, []);

  useEffect(() => {
    if (showAll === true) {
      setMatched(true);
    } else if (showAll === false) {
      setMatched(false);
    }
  }, [showAll]);

  useEffect(() => {
    setComplianceState(complianceAtom);
    setResetPagination(resetPagination + 1);
    const tempData: any = screenTopperSettings;
    tempData.matching = {
      year: year,
      quarter: quarter,
      market: market,
      matched: matched,
      search: search,
      allUserMarkets: allUserMarkets,
      showLatest: showLatest,
      showAll: showAll,
      advTableFilterOption: advTableFilterOption
    };
    setScreenTopperSettings(tempData);
  }, [year, quarter, market, matched, search, allUserMarkets, showLatest, showAll, advTableFilterOption]);

  const dismissMatching = async (params: any) => {
    await makeRequest("POST", urlPaths.POLITICAL_MATCHES_DISMISS_MATCHING, {
      csv_id: params.csv_id,
      username: userInfo.username
    }, null).then(() => {
      setSnackBar("Order has been dismised!", "success");
      setComplianceState(complianceAtom);
    }).catch((error) => {
      setSnackBar("Error: " + error, "error");
      console.log("error", error);
    });
  };

  const
    matches = [{
      field: "candidate_campaign_name",
      id: "candidate_campaign_name",
      headerName: "Campaign",
      flex: 1,
    }, {
      field: "path",
      id: "path",
      headerName: "Location",
      flex: 1,
      renderCell: (params: { row: { fcc_path: string, year: string } }) => {
        return <p>{params?.row && params?.row?.fcc_path ? params?.row?.fcc_path
          .split(params?.row?.year + "/")[1]
          .split("/")
          .map((segment, index, array) => (
            <React.Fragment key={index}>
              {segment}
              {index < array.length - 1 && <strong style={{fontWeight: "900", fontSize: "medium"}}> / </strong>}
            </React.Fragment>
          )) : ""}</p>;
      }
    }, {
      field: "name",
      id: "name",
      headerName: "File",
      flex: 1,
      renderCell: (params: { row: { name: string, file_id: string } }) => {
        return <Link to={`/political-candidates-and-issues/document/${params.row.file_id}`}
          style={{
            color: "#1976d2",
          }} rel="noreferrer"
        >{params?.row?.name}</Link>;
      }
    }, {
      field: "upload_date",
      id: "upload_date",
      headerName: "Upload Date",
      width: 220,
      renderCell: (params: { row: { upload_date: string } }) => {
        return convertDate(params.row.upload_date);
      },
    }, {
      field: "score",
      id: "score",
      headerName: "Score",
      width: 100,
      renderCell: (params: { row: { score: number } }) => {
        return (Math.round((params?.row?.score * 100) + Number.EPSILON));
      },
    }, {
      field: "matching",
      id: "matching",
      align: "left",
      type: "actions",
      headerName: "Matching",
      width: 120,
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      getActions: (params: { row: { match: boolean, id: string, csv_id: string, file_id: string } }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const [checked, setChecked] = useState(params.row.match);
        return [<Checkbox
          key={params.row.id}
          {...{inputProps: {"aria-label": "Checkbox demo"}}}
          checked={checked}
          onChange={async (e) => {
            if (e.target.checked === true) {
              setChecked(true);
            } else {
              setChecked(false);
            }
            await makeRequest("POST", `${e.target.checked === false ? urlPaths.POLITICAL_MATCHES_REMOVE : urlPaths.POLITICAL_MATCHES_SET}`, {
              csv_id: params.row.csv_id,
              file_id: params.row.file_id,
              username: userInfo.username,
              method: e.target.checked === false ? undefined : "matching",
              form: e.target.checked === false ? undefined : "matching"
            }, null).then((data: any) => {
              if (e.target.checked === true && data.success === true) {
                setSnackBar("Match has been made", "success");
              } else if (e.target.checked === false && data.success === true) {
                setSnackBar("Unmatch has been performed", "success");
              } else if (e.target.checked === false && !data.success) {
                setSnackBar("Unmatch has been performed", "error");
              }
            }).then(() => {
              console.log("Updating data", params.row);
            }).catch((error) => {
              setSnackBar("Error: " + error, "error");
              console.log("error", error);
            });
          }}
        />];
      }
    }],
    mobileColumns = [{
      field: "candidate_campaign_name",
      id: "candidate_campaign_name",
      headerName: "Campaign",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: Matches) => {
        return (<Grid container spacing={3}>
          <Grid xs sx={{minHeight: "fit-content", height: "auto", display: "inline-block"}}>
            <div style={{lineHeight: "normal"}}><Box
              component="img"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
              loading="lazy"
              sx={{
                lineHeight: "normal",
                width: "auto",
                maxHeight: {xs: 90, md: 90},
              }}
              alt={`${params.callsign} Logo`}
              src={`/craft/rest/image?facility_id=${params.fcc_markets.facId}`}
            />
            </div>
          </Grid>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
              <span><strong>Market:</strong> {params.market}</span>
              <span><strong>Callsign:</strong> {params.callsign}</span>
              <span><strong>Service:</strong> {params.fcc_markets.service}</span>
              <span><strong>Facility ID:</strong> {params.fcc_markets.facId}</span>
            </Stack>
          </Grid>
          <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
              <span><strong>Advertiser:</strong> {params.advertiser}</span>
              <span><strong>Type:</strong> {params.order_type.toLowerCase().replace("political ", "")}</span>
              <span><strong>Date Ordered:</strong> {convertDatePretty(params.date_created)}</span>
            </Stack>
            <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
              <Stack direction="column" spacing={.5}>
                <Stack direction="row" sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }} spacing={1}>
                  <Button
                    key={uuidv4()}
                    sx={{
                      minWidth: "fit-content",
                      minHeight: "fit-content",
                      padding: "4px",
                      "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
                    }}
                    onClick={(() => {
                      navigate(`/political-candidates-and-issues/upload/${params.csv_id}`);
                        // setModalData(params);
                        // setModalState(!modalState);
                    })}
                  ><UploadFileIcon sx={{
                      color: "#1976d2",
                      fontSize: "1.6rem",
                    }}/> Upload</Button>
                  <Button
                    key={uuidv4()}
                    sx={{
                      minWidth: "fit-content",
                      minHeight: "fit-content",
                      padding: "4px",
                      "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
                    }}
                    onClick={(() => {
                      dismissMatching(params);
                    })}
                  ><RemoveCircleOutlineIcon sx={{
                      color: "#1976d2",
                      fontSize: "1.6rem",
                    }}/> Remove</Button></Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        );
      }
    }],
    columns = [{
      field: "select",
      id: "select",
      align: "left",
      type: "string",
      headerName: "Select",
      width: 40,
      // flex: 1,
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (<Checkbox
          id={uuidv4()}
          key={uuidv4()}
          checked={bulkUploadDocs.includes(params.csv_id)}
          onChange={(e) => {
            if (e.target.checked === true) {
              setBulkUploadDocs((prev: any) => [...prev, params.csv_id]);
            } else {
              setBulkUploadDocs((prev: any) => {
                // delete prev[params.csv_id];
                return prev.filter((csv_id: string) => {
                  if (csv_id !== params.csv_id) {
                    return csv_id;
                  }
                });
              });
            }
          }}
        />);
      }
    }, {
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: Matches) => {
        return <Link to={`/market/${params?.market}/`}>{params?.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 40,
      align: "left",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: Matches) => <Brand {...{
        callsign: params?.fcc_markets?.calls,
        facility_id: params?.fcc_markets?.facId,
        service: params?.fcc_markets?.service
      }} />
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 70,
      renderCell: (params: Matches) => {
        return <Link to={`/facility/${params?.fcc_markets?.facId}/`}>{params?.fcc_markets?.calls}</Link>;
      }
    }, {
      field: "order_type",
      id: "order_type",
      headerName: "Type",
      width: 70,
      renderCell: (params: Matches) => {
        return <p
          style={{textTransform: "capitalize"}}>{params.order_type?.toLowerCase().replace("political", "")}</p>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "date_created",
      id: "date_created",
      headerName: "Created",
      width: 100,
      renderCell: (params: Matches) => {
        return convertDatePretty(params.date_created);
      },
    }, {
      field: "flight_start",
      id: "flight_start",
      headerName: "Start",
      // flex: 1,
      width: 100,
      renderCell: (params: Matches) => {
        return convertDatePretty(params.flight_start);
      },
    }, {
      field: "flight_end",
      id: "flight_end",
      headerName: "End",
      // flex: 1,
      width: 100,
      renderCell: (params: Matches) => {
        return convertDatePretty(params.flight_end);
      },
    }, {
      field: "order_number",
      id: "order_number",
      align: "left",
      headerName: "Order #",
      // flex: 1,
      width: 70,
      renderCell: (params: Matches) => {
        return params?.order_number;
      }
    }, {
      field: "estimate",
      id: "estimate",
      align: "left",
      headerName: "Est #",
      width: 70,
      renderCell: (params: Matches) => {
        return params.estimate;
      }
    }, {
      field: "advertiser",
      id: "advertiser",
      align: "left",
      headerName: "Advertiser",
      flex: 5,
      renderCell: (params: Matches) => {
        return <Link
          to={`/political-candidates-and-issues/advertiser/${params?.advertiser}`}>{params.advertiser}</Link>;
      }
    }, {
      field: "ae_name",
      id: "ae_name",
      align: "left",
      headerName: "AE",
      flex: 1,
      renderCell: (params: Matches) => {
        return <Link to={`/political-candidates-and-issues/ae/${params?.ae_name}`}>{params.ae_name}</Link>;
      }
    }, {
      field: "date_created",
      id: "date_created",
      align: "left",
      headerName: "Pastdue",
      // flex: 2,
      width: 80,
      renderCell: (params: {
        matched: boolean,
        upload_process_id: string,
        date_created: string,
        possibleMatches: any
      }) => {
        let completedCount = 0;
        params?.possibleMatches?.map((match: any) => {
          if (match.match === true) {
            completedCount += 1;
          }
        });
        const count = daysBetweenDates(params.date_created) * -1;
        return completedCount > 0 || completedCount === 0 && params.matched ? null : count + (count > 1 ? " Days" : " Day");
      },
    }, {
      field: "possibleMatches",
      id: "possibleMatches",
      align: "left",
      headerName: "Status",
      flex: 1,
      renderCell: (params: any) => {
        let completedCount = 0;
        params?.possibleMatches?.map((match: any) => {
          if (match.match === true) {
            completedCount += 1;
          }
        });
        return params.matched && completedCount > 0 ? <><CircleIcon
          sx={{color: "green"}}/> {completedCount} Files</> :
          params.matched && completedCount <= 0 ? <><CircleIcon sx={{color: "orange"}}/>Skipped</> :
            params?.possibleMatches?.length <= 0 ? <><CircleIcon sx={{color: "red"}}/> No Matches</> :
              <><CircleIcon sx={{color: "red"}}/> {params?.possibleMatches?.length} Pending</>;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      headerName: "Actions",
      width: 120,
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      renderCell: (params: any) => <Stack direction="row" spacing={1}>
        <Tooltip
          title="Upload Docs"
          enterDelay={1200}
          // leaveDelay={500}
          TransitionProps={{timeout: 200}}
        >
          <Button
            key={uuidv4()}
            sx={{
              borderRadius: "50%",
              minWidth: "fit-content",
              minHeight: "fit-content",
              padding: "4px",
              "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
            }}
            onClick={(() => {
              navigate(`/political-candidates-and-issues/upload/${params.csv_id}`);
              // setModalData(params);
              // setModalState(!modalState);
            })}
          ><UploadFileIcon sx={{
              color: "#1976d2",
              fontSize: "1.7rem",
            }}/></Button></Tooltip>
        <Tooltip
          title="Ignore Document Requirement"
          enterDelay={1200}
          // leaveDelay={500}
          TransitionProps={{timeout: 200}}
        ><Button
            key={uuidv4()}
            sx={{
              borderRadius: "50%",
              minWidth: "fit-content",
              minHeight: "fit-content",
              padding: "4px",
              "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
            }}
            onClick={(() => {
              dismissMatching(params);
            })}
          ><RemoveCircleOutlineIcon sx={{
              color: "#1976d2",
              fontSize: "1.7rem",
            }}/></Button></Tooltip></Stack>
    }],
    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload Docs",
        type: "link",
        location: "internal",
        href: "/political-candidates-and-issues/upload/",
        disabled: bulkUploadDocs.length > 0 ? false : true
      }, {
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/matching/"
      }]
    },
    topperProps: any = {
      title: props.mobile ? "Orders Missing Docs" : "WideOrbit Orders Missing Documents",
      inputs: [{
        label: "Past 30 Days",
        type: "switch",
        defaultValue: true,
        state: showLatest,
        setState: setShowLatest
      }, {
        label: "Filter",
        type: "select",
        multiple: false,
        width: "270px",
        options: ["Show All", "Show Completed", "Show Pending"],
        defaultValue: "Show All",
        state: advTableFilterOption,
        setState: setAdvTableFilterOption,
        // display: !showLatest
      }, {
        label: "Search",
        type: "custom",
        width: "200px",
        component: () => <FormControl
          id={"search"}
          key={"search"}
          style={{maxHeight: "40px", minWidth: "200px", marginLeft: "16px"}}
        >
          <TextField
            id={"searchField"}
            name={"searchField"}
            label={null}
            placeholder={"Search"}
            value={search}
            autoFocus
            // inputRef={input => input && input.focus()}
            onChange={handleSearchChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              margin: "dense",
              disableUnderline: true,
              style: {
                backgroundColor: "white",
                maxHeight: "40px",
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        width: "180px",
        options: getArrayOfYears(),
        defaultValue: topperSettings?.matching?.year ? topperSettings.matching.year : getCurrentYear(),
        state: year,
        setState: setYear,
        display: !showLatest
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        width: "200px",
        options: getArrayOfQuarters(year),
        defaultValue: topperSettings?.matching?.quarter ? topperSettings.matching.quarter : `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter,
        display: !showLatest
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: topperSettings?.matching?.market ? topperSettings.matching.market : "My Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: (() => {
          setScreenTopperSettings({
            ...screenTopperSettings,
            "matching": {}
          });
          setMatched(false);
          setShowLatest(true);
          setAdvTableFilterOption("Show All");
          setShowAll(true);
          setYear(getCurrentYear());
          setQuarter(`Q${getCurrentQuarter()}`);
          setMarket("My Markets");
          setYear(getCurrentYear());
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Stack direction="column"
        spacing={0}>
        <Title {...{
          title: "Political Candidates & Issues",
          video: {
            title: "Manage Orders",
            src: "/craft/rest/training/Political-ManageOrders.mp4"
          },
          mobile: props.mobile,
        }} />
        <Breadcrumb {...{
          breadcrumb: ["Home", "Political Candidates & Issues"]
        }} />
        <ButtonBar {...buttonBarButons} />
        <Topper {...{
          ...topperProps,
          mobile: props.mobile
        }} />
        <CollapseTable {...{
          data: data?.items && data?.items?.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
          columns: props.mobile ? mobileColumns : columns,
          pending: isPending,
          tableKey: "politicalMatchingTable",
          mobile: props.mobile,
          resetPagination: resetPagination,
          tableSortBy: "date_created",
          subTableColumns: matches,
          subTableKey: "possibleMatches",
          subTableSortBy: "score",
          loading: isPending,
          error: isError,
          errorMessage: errorState
        }} />
      </Stack>
    </Suspense>
  );
};