import React, {lazy, Suspense} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@emotion/styled";
import Skeleton from "@mui/material/Skeleton";
import {v4 as uuidv4} from "uuid";
import {NavLink as Link} from "react-router-dom";

const
  Typography = lazy(() => import("@mui/material/Typography")),
  Breadcrumbs = lazy(() => import("@mui/material/Breadcrumbs")),
  Home = lazy(() => import("@mui/icons-material/Home"));

export function Breadcrumb(props: any) {
  const
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    Icon = styled(Home)`
      width: 20px;
      height: 20px;
      margin-right: 2px;
      margin-left: ${(isSmallScreen) ? "15px" : "0px"};
      margin-top: -2px;
    `,
    Bc = styled(Breadcrumbs)`
      padding: 0px 25px 15px;
      flex-wrap: wrap;
      border-top: 1px solid #ddd;
      align-items: center;
      margin-left: -25px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 25px;
      background-color: white;
      padding-top: 15px;
      //width: 100vw;
      padding-right: 25px;
      //width: 100%; 
      width: calc(100% + 50px);
      overflow-x: hidden !important;
    `,
    LinkItem = styled(Link)`
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 400;
      letter-spacing: 0.00938em;
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        text-decoration: underline;
      }
    `,
    ActiveItem = styled(Typography)`
      font-family: Roboto, Helvetica, Arial, sans-serif;
      font-weight: 500;
      letter-spacing: 0.00938em;
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.87)
    `;


  const updateBreadCrumb = (id: any) => {
    if (id) {
      if (props.facilityData !== null && props?.facilityData?.callsign && id.includes(":id")) {
        return id.replace(":id", props.facilityData.callsign);
      } else if (props.facilityData !== null && props?.facilityData?.callsign && id.includes(":facility_id")) {
        return id.replace(":facility_id", props.facilityData.callsign);
      } else if (props?.topLevelData && id.includes(":id")) {
        return id.replace(":id", props.topLevelData.document_name);
      } else {
        return id;
      }
    } else {
      return "";
    }
  };

  return (
    <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={54}/>}>
      <Bc aria-label="breadcrumb">
        {props.breadcrumb.map((prop: any, index: any) => {

          // The breadcrumb for the active page
          if (index == (props.breadcrumb.length - 1)) {
            return <ActiveItem color="text.primary" key={uuidv4()}>{updateBreadCrumb(prop).toString()}</ActiveItem>;
          }

          // Home link
          else if (index === 0) {
            return <div key={uuidv4()}>
              <Icon/>
              <LinkItem key={uuidv4()} color="inherit"
                to={"/"}> {updateBreadCrumb(prop).toString()}</LinkItem>
            </div>;
          }

          // Not the home link
          else if (index !== 0 && prop !== "Home" && prop.includes("/")) {
            const
              text = prop.toString().includes("market") && !prop.toString().includes("markets") ?
                updateBreadCrumb(prop).toString().replace(/-/g, " ").replace(/\//ig, " ").replace("market ", "") :
                updateBreadCrumb(prop).toString().toLowerCase().replace(/-/g, " ").replace(/\//ig, " "),
              href = !prop.toString().includes("market") ?
                updateBreadCrumb(prop).toString().toLowerCase().replace(/ /g, "-").replace("&", "and") :
                updateBreadCrumb(prop).toString().replace(/ /g, "%20").replace("&", "and");
            return <LinkItem key={uuidv4()} color="inherit" style={{textTransform: "capitalize"}}
              to={`/${href.replace("&", "and")}`}>{text}</LinkItem>;
          }

          // All other breadcrumbs
          else {
            return <LinkItem key={uuidv4()} color="inherit"
              to={`${prop !== "Home" && !prop.includes("market") ?
                prop.toLowerCase().replace(/ /g, "-").replace("&", "and") :
                prop.includes("market") ?
                  prop.replace(/ /g, "%20").replace("&", "and") :
                  ""}`}>{updateBreadCrumb(prop).toString()}</LinkItem>;
          }


        })}
      </Bc>
    </Suspense>
  );
}