import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {NavLink as Link} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {v4 as uuidv4} from "uuid";
import ButtonBar from "components/ui/buttonbar";
import FccPoliticalFetchNewFccDocuments from "components/ui/forms/political_fetch_new_fcc_documents";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {Title} from "components/ui/pagetitle";
import {Breadcrumb} from "components/ui/breadcrumb";
import {screenTopperSettings} from "services/jotai/political/political";

const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  Brands = lazy(() => import("components/ui/table/components/cell/Brands").then((module) => ({default: module["Brands"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Button = lazy(() => import("@mui/material/Button")),
  Box = lazy(() => import("@mui/material/Box")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Stack = lazy(() => import("@mui/material/Stack"));
export const FccPoliticalAes = (props: any) => {
  const
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [topperSettings, setScreenTopperSettings]: any = useAtom<any>(screenTopperSettings),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {getCurrentYear, getArrayOfYears, convertDate} = useDate(),
    {getDocumentQuarter} = useQuarter(),
    [compliant, setCompliance] = useState<any>(topperSettings?.aes?.compliant ? topperSettings.aes.compliant : false),
    [year, setYear] = useState<any>(topperSettings?.aes?.year ? topperSettings.aes.year : getCurrentYear()),
    [quarter, setQuarter] = useState<any>(topperSettings?.aes?.quarter ? topperSettings.aes.quarter : `Q${getCurrentQuarter()}`),
    [market, setMarket] = useState(topperSettings?.aes?.market ? topperSettings.aes.market : "My Markets"),
    [quartersArray, setQuartersArray] = useState(getArrayOfQuarters(year.toString(), true)),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    advertiserAtom = requestAtom("PoliticalAdvertisers", urlPaths.POLITICAL_AES, {
      year: year,
      market: props?.facility_id ? undefined : props?.market ? [props.market] : market === "My Markets" ? allUserMarkets : [market], /*market,*/
      facility_id: props?.market ? undefined : props?.facility_id ? props.facility_id : undefined,
      quarter: quarter,
    }, setErrorState),
    [advertisersState, setAdvertisersState] = useState<any>(() => advertiserAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(advertisersState);

  useEffect(() => {
    // Reset the quarter dropdown if the user is switching between years so its not a blank select
    if (quarter !== "All Quarters" && Number(getCurrentYear()) <= year && Number(getCurrentQuarter()) < Number(quarter.split("Q")[1])) {
      setQuarter(`Q${getCurrentQuarter()}`);
    }
    const tempData: any = screenTopperSettings;
    tempData.aes = {
      year: year,
      quarter: quarter,
      market: market,
      compliant: compliant,
      allUserMarkets: allUserMarkets
    };
    setScreenTopperSettings(tempData);
  }, [year, quarter, market, compliant, allUserMarkets]);


  useEffect(() => {
    if (compliant) {
      // Remove the all quarters select option when viewing compliant documents
      if (quarter === "All Quarters") {
        setQuarter(`Q${getCurrentQuarter()}`);
      }
      setQuartersArray(getArrayOfQuarters(year.toString(), false));
    } else {
      setQuartersArray(getArrayOfQuarters(year.toString(), true));
    }
  }, [year, quarter, market, compliant, allUserMarkets]);


  useEffect(() => {
    return setAdvertisersState(advertiserAtom);
  }, [year, quarter, market, compliant, isError, allUserMarkets]);

  useEffect(() => {
    cachedMarketsData();
  }, []);


  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          fcc_directory: string,
          containing_folder_link: string,
          date_required: string,
          files: {
            file_id: string,
            link: string,
            name: string,
            upload_date: string,
            campaign_name: string,
            quarter: number
          }[]
        }
      }) => {
        return <Grid key={uuidv4()} container spacing={3}>
          <Grid xs sx={{minHeight: "fit-content", height: "auto", display: "inline-block"}}>
            <div style={{lineHeight: "normal"}}><Box
              component="img"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              loading="lazy"
              sx={{
                lineHeight: "normal",
                width: "auto",
                maxHeight: {xs: 50, md: 50},
              }}
              alt={`${params.row.callsign} Logo`}
              src={`/craft/rest/image?facility_id=${params.row.facility_id}`}
            />
            </div>
          </Grid>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
              <span>Market: {params.row.market}</span>
              <span>Callsign: {params.row.callsign}</span>
              <span>Service: {params.row.service}</span>
              <span>Facility ID: {params.row.facility_id}</span>
            </Stack>
          </Grid>
          <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
              <Stack direction="column" spacing={1}>{params?.row?.files?.map((doc: {
                file_id: string, link: string, name: string, upload_date: string, campaign_name: string, quarter: number
              }) => {
                return (
                  <Button key={uuidv4()} size={"small"} variant="contained"
                    href={`/craft/view/political-candidates-and-issues/document/${doc.link}`}
                    sx={{boxShadow: 0, width: "100%"}}>
                    <Stack direction="row" spacing={2}>
                      <strong style={{
                        fontWeight: "600",
                        maxWidth: "180px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      }}>{doc.name}</strong>
                      <h5 style={{
                        fontWeight: "100"
                      }}>Q{getDocumentQuarter(doc.upload_date)}</h5>
                      <h5 style={{
                        fontWeight: "100"
                      }}>{convertDate(doc.upload_date)}
                      </h5>
                    </Stack></Button>);
              })} <Button variant="outlined"
                href={params.row.containing_folder_link}
                sx={{boxShadow: 0, width: "100%"}}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target={"_blank"}
                key={uuidv4()}>
                <FolderIcon/>
                <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC ISSUES & PROGRAMS</span>
              </Button></Stack>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    columns = [{
      field: "ae_name",
      id: "ae_name",
      headerName: "AE Name",
      flex: 1,
      renderCell: (params: { row: { ae_name: string } }) => {
        return <Link key={uuidv4()}
          to={`/political-candidates-and-issues/ae/${params.row.ae_name}/`}>{params.row.ae_name}</Link>;
      }
    }, {
      field: "count",
      id: "count",
      headerName: "Doc Count",
      width: 140,
      renderCell: (params: { row: { count: string } }) => {
        return params.row.count;
      }
    }, {
      field: "count_brands",
      id: "count_brands",
      headerName: "Brand Count",
      width: 110,
      renderCell: (params: { row: { count_brands: string } }) => {
        return params.row.count_brands;
      },
    }, {
      field: "markets",
      id: "markets",
      headerName: "Markets",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { brands: { market_name: string }[] } }) => {
        const brands = new Set(params.row.brands.map((brand) => brand.market_name));
        return <Grid container spacing={1} columns={{xs: 12, sm: 12, md: 12}}>{[...brands].map(((brand: string) => <Grid
          item key={uuidv4()}><Link key={uuidv4()} to={`/market/${brand}/`}>{brand}</Link></Grid>))}</Grid>;
      },
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { brands: { facility_id: string, callsign: string }[] } }) => {
        const brands = new Set(params.row.brands.map((brand) => ({
          callsign: brand.callsign,
          facility_id: brand.facility_id
        })));

        return <Grid container spacing={1} columns={{xs: 12, sm: 12, md: 12}}>{[...brands].map(((brand: {
          callsign: string,
          facility_id: string
        }) => <Grid item key={uuidv4()}><Link key={uuidv4()}
          to={`/facility/${brand.facility_id}/`}>{brand.callsign}</Link></Grid>))}</Grid>;
      }
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brands",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: { brands: { facility_id: string, service: string, callsign: string, image: string }[] }
      }) => {
        return <Brands {...{
          results: params.row.brands
        }} />;
      }
    }],

    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          data: {
            market: props?.market ? props.market : undefined,
            facility_id: props?.facility_id ? props.facility_id : undefined,
          },
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: []
    },
    topperProps: any = {
      title: props?.title ? props.title : "All Account Executives",
      inputs: [{
        label: <span style={{fontSize: "10px"}}><FolderIcon
          sx={{marginRight: "5px", marginTop: "-5px"}}/>VIEW FCC POLITICAL FILES</span>,
        type: "button",
        onClick: (() => {
          window.open(data?.base_folder_link);
        }),
        display: props?.showFccLink && data && data?.base_folder_link ? props.base_folder_link : false
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        width: "150px",
        options: getArrayOfYears(),
        defaultValue: topperSettings?.aes?.year ? topperSettings.aes.year : getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        width: "200px",
        options: quartersArray,
        defaultValue: topperSettings?.aes?.quarter ? topperSettings.aes.quarter : `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: topperSettings?.aes?.market ? topperSettings.aes.market : "My Markets",
        state: market,
        setState: setMarket,
        display: !props?.market && !props?.facility_id ? true : false
      }, {
        label: "Reset",
        type: "button",
        onClick: (() => {
          setCompliance(false);
          setMarket("My Markets");
          setYear(getCurrentYear());
          setQuarter(`Q${getCurrentQuarter()}`);
          setScreenTopperSettings({
            ...screenTopperSettings,
            "aes": {}
          });
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Candidates & Issues",
        video: {
          title: "By AE",
          src: "/craft/rest/training/Political-ByAe.mp4"
        },
        mobile: props.mobile,
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Political Candidates & Issues/matching/", "All Aes"]
      }} />
      <ButtonBar {...buttonBarButons} />
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        tableKey: "aesTable",
        mobile: props.mobile,
        data: data?.items?.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : columns,
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        sortBy: "count",
        // minHeight: data?.items.length > 0 ? "800px" : undefined
      }} />
    </Suspense>
  );
};