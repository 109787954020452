import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ModalFooter from "components/ui/modal/components/ModalFooter";
import MultiSelect from "components/ui/forms/components/fields/MultiSelect";
import React, {lazy, useEffect, useState} from "react";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useMainSnackBar} from "hooks/useMainSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {cachedRequests} from "services/api/cachedRequests";
import {allTheFacilitiesData, allTheMarketsData} from "services/jotai/requests/requests";
import urlPaths from "services/api/urlPaths";
import dayjs from "dayjs";
import {NavLink as Link} from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";

interface IFormInputs {
  Markets: any | string | string[],
  Callsigns: any[],
  PdfFile: any,
  Files: any[],
  Type: string,
  OfficeType: string,
  Office: string,
  Years: number | null,
  Quarters: any,
  CandidateOrIssueName: string,
  CustomFolder: string,
  CustomFolders: string[],
  FilePath: string[],
}

interface Market {
  name: string;
  id: string | number;
}

interface MarketOutput {
  label: string;
  id: string | number;
  facility_id?: string | number;
}

const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

const getMarkets = (allMarketsData: Market[]): MarketOutput[] => {
  const allMarkets: Set<MarketOutput> = new Set();

  allMarketsData?.forEach((market: Market) => {
    allMarkets.add({label: market.name, id: market.name});
  });

  return Array.from(allMarkets);
};
const getCallsigns = (selectedMarkets: any, tsMarkets: any) => {
  const allCallsigns: Set<any> = new Set([]);
  if (tsMarkets && selectedMarkets && selectedMarkets.length > 0) {
    if (typeof selectedMarkets === "string") {
      tsMarkets?.forEach((tsMarket: any) => {
        if (tsMarket["name"].toLowerCase() === selectedMarkets.toLowerCase()) {
          tsMarket.brands.forEach((brands: any) => {
            allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
          });
        }
      });
      return Array.from(allCallsigns);
    } else {
      tsMarkets?.forEach((tsMarket: any) => {
        selectedMarkets.forEach((selectedMarket: any) => {
          if (tsMarket["name"].toLowerCase() === selectedMarket.toLowerCase()) {
            tsMarket.brands.forEach((brands: any) => {
              allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
            });
          }
        });
      });
      return Array.from(allCallsigns);
    }
  } else {
    return [];
  }
};


export default function FccPoliticalFetchNewFccDocuments(props: any) {
  let noNewDocs: string[] = [];

  const
    {makeRequest} = useApiAuth(),
    {
      getCurrentYear,
      getArrayOfYears,
    } = useDate(),
    {cachedMarketsData} = cachedRequests(),
    [allMarketsData] = useAtom(allTheMarketsData),
    [allTFacilitiesData] = useAtom<any>(allTheFacilitiesData),
    [submitted, setSubmitted] = useState(false),
    {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        Years: getCurrentYear(),
        Markets: [],
        Callsigns: [],
      },
    }),
    year: any = useWatch({control, name: "Years"}),
    market = useWatch({control, name: "Markets"}),
    [marketNames, setMarketNames] = useState<any>([]),
    [tableData, setTableData] = useState<any>([]),
    [marketCallsigns, setMarketCallsigns] = useState([]),
    yearOptions = getArrayOfYears(),
    {setMainSnackBar} = useMainSnackBar(),
    getNewFccFiles = async (facilityId: string) => {
      return makeRequest("GET", urlPaths.POLITICAL_UPDATE_FCC_FILES, null, {
        year: year,
        facility_id: facilityId,
        start_date: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        end_date: dayjs().format("YYYY-MM-DD")
      }).then(async (response: any) => {
        if (response) {
          console.log("response", response);
          if (!response.updated_files || response.updated_files && response.updated_files.length <= 0) {
            await noNewDocs.push(allTFacilitiesData[facilityId].callsign);
            await setTableData((prev: any) => prev.map((brand: any) => {
              if (brand.facility_id === facilityId) {
                brand.documents = 0;
                brand.status = true;
              }
              return brand;
            }));
          } else {
            await setTableData((prev: any) => prev.map((brand: any) => {
              if (brand.facility_id === facilityId) {
                brand.documents = response.updated_files.length;
                brand.status = true;
              }
              return brand;
            }));
            await setMainSnackBar("success", `Added ${response.updated_files.length} new FCC files for ${allTFacilitiesData[facilityId].callsign} to SignalCraft!`, "success");
          }
        }
      }).catch((error: any) => {
        setMainSnackBar("error", "Oh no there was an error adding your document!", "error");
        console.log("error", error);
      });
    },
    resolver = async (data: any) => {
      const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
      console.log("submit data", data);
      console.log("allTFacilitiesData", allTFacilitiesData);
      noNewDocs = [];

      const buildingTableData: {
        id: string,
        callsign: string,
        facility_id: string,
        market: string,
        service: string,
        documents: number,
        status: boolean
      }[] = [];

      await data?.Callsigns.map((facilityId: any) => {
        buildingTableData.push({
          id: allTFacilitiesData[facilityId].id,
          callsign: allTFacilitiesData[facilityId].callsign,
          facility_id: facilityId,
          market: allTFacilitiesData[facilityId].market,
          service: allTFacilitiesData[facilityId].service,
          documents: 0,
          status: false
        });
      });

      setTableData(buildingTableData);

      setSubmitted(true);

      for await (const facilityId of data.Callsigns) {
        await getNewFccFiles(facilityId);
        await sleep(1000);
      }

      // await sleep(4000);
      reset(); // Reset the form state back to the default state
      setTimeout(async () => {
        props.handleChange(); // Close the modal
        if (noNewDocs.length > 0) {
          await setMainSnackBar("success", `No new documents located for [${noNewDocs.toString()}] on the FCC.`, "info");
        }
      }, 2000);

    },
    onSubmit: SubmitHandler<IFormInputs> = (data: any) => resolver(data);

  useEffect(() => {
    if (props.submit) {
      console.log("trying to submit", props.submit);
      trigger().then((isValid) => {
        if (isValid) {
          handleSubmit(onSubmit)(); // Submits the form if all validations pass
        }
      });
    }
  }, [props.submit]);


  // Populate data once the API returns the rest markets data
  useEffect(() => {
    cachedMarketsData();

    // Select the market if the data was sent
    if (props?.data?.market) {
      setValue("Markets", [props?.data?.market]);
    }
    // Select the facility in the dropdown if the data was sent
    if (props?.data?.facility_id && allTFacilitiesData) {
      setValue("Markets", [allTFacilitiesData[props?.data?.facility_id].market]);
      setTimeout(() => {
        setValue("Callsigns", [Number(props?.data?.facility_id)]);
      }, 2000);
    }
  }, []);

  // Set the market names inside the select
  useEffect(() => {
    if (allMarketsData) {
      const allTsmMarkets: MarketOutput[] = getMarkets(allMarketsData);
      setMarketNames(allTsmMarkets);
    }
  }, [allMarketsData]);

  // Wipe callsign selections and repopulate the select options with new callsigns each time the markets dropdown changes
  useEffect(() => {
    setValue("Callsigns", []);
    const allCallSigns: any = getCallsigns(market, allMarketsData);
    setMarketCallsigns(allCallSigns);
  }, [market]);

  const
    topperProps: any = {
      title: "Import Results",
      inputs: []
    },
    columns: any = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return (<Link
          to={`/market/${params.row.market}`}
        >{params.row.market}</Link>);
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Callsign",
      // align: "center",
      flex: 1
    }, {
      field: "documents",
      id: "documents",
      headerName: "Added",
      // align: "center",
      flex: 1,
    }, {
      field: "status",
      id: "status",
      // align: "center",
      headerName: "Status",
      flex: 1,
      renderCell: (params: { row: { status: boolean } }) => {
        return params.row.status ? <CircleIcon sx={{color: "green"}}/> : <CircleIcon
          sx={{color: "red"}}/>;
      }
    }];

  return (
    <form onSubmit={handleSubmit(onSubmit)} name={"political_fetch_new_fcc_documents"}>
      <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 2}} sx={{padding: "20px"}}>
        <Grid item xs={12}>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Years",
              label: "Year",
              multiSelect: false,
              validationMessage: "Select a year",
              options: yearOptions,
              required: true,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Markets",
              label: "Markets",
              multiSelect: true,
              validationMessage: "Select at least one market",
              options: marketNames && marketNames.length > 0 ? marketNames : [],
              disabled: marketNames && marketNames.length === 0 ? true : false,
              required: true,
              handleChange: onSubmit,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Callsigns",
              label: "Callsigns",
              multiSelect: true,
              validationMessage: "Select at least one callsign",
              options: marketCallsigns,
              disabled: marketCallsigns.length === 0 ? true : false,
              required: true,
              handleChange: onSubmit,
              control: control
            }}  />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "11px",
            marginTop: "14px",
            marginBottom: "14px",
            maxWidth: "75%",
            margin: "15px auto -15px auto"
          }}><strong>Note:</strong> This process fetches all missing political documents that exist on the FCC and are
            absent from SignalCraft. This process is automatically performed behind the scenes periodically, however,
            if you&apos;re missing a newly uploaded document this tool is designed to help.</p>
        </Grid>
        {tableData.length > 0 ? <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Import Results:</strong></p>
          <Table {...{
            ...props,
            sortBy: "market",
            mobile: props.screen,
            data: tableData ? tableData : [],
            columns: props.screen ? columns : columns,
            tableSortBy: "market",
            loading: false,
            error: false,
            errorMessage: null
          }} />
          {/*</Box>*/}
        </Grid> : <></>}
      </Grid>

      <ModalFooter {...{
        buttons: {
          left: [{
            variant: "contained",
            color: "secondary",
            name: "cancel",
            type: null,
          }],
          right: [{
            variant: "contained",
            color: null,
            name: "Fetch New Documents",
            type: "submit",
          }]
        },
        close: props.handleChange,
        submitButtonState: marketCallsigns.length === 0 && submitted !== false ? true : false
      }} />
    </form>
  );
}