import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";

const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);


// Using the custom local storage hook
// import {useQuarter} from "../hooks/useLocalStorage.tsx";
// const {setItem, getItem, removeItem} = useLocalStorage('someKey');
export const useDate = () => {

  const gracePeriod = 10;

  const getCurrentDate = () => {
    try {
      return dayjs(new Date());
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentYear = () => {
    try {
      return dayjs(new Date()).subtract(gracePeriod, "days").year();
    } catch (error) {
      console.log(error);
    }
  };

  const getArrayOfYears = () => {
    try {

      const startYear = dayjs(new Date("01 " + "July 2022")).year();
      const endYear = dayjs(new Date()).subtract(gracePeriod, "days").year();
      const years = [];
      for (let i = startYear; i <= endYear; i++) years.push(i);
      return years.reverse();
    } catch (error) {
      console.log(error);
    }
  };

  const getArrayOfWeeks = () => {
    try {
      const gracePeriod = 0; // Define your grace period in days
      const startDate = dayjs("2024-01-01").startOf("week");
      const endDate = dayjs().subtract(gracePeriod, "days").endOf("week");
      const weeks = [];
      let currentDate: any = startDate;

      while (currentDate.isSameOrBefore(endDate)) {
        const weekStart = currentDate.startOf("week").format("MM/DD/YYYY");
        const weekEnd = currentDate.endOf("week").format("MM/DD/YYYY");
        weeks.push(`${weekStart} - ${weekEnd}`);
        currentDate = currentDate.add(1, "week");
      }

      return weeks.reverse(); // Reverse to have the most recent weeks first
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrentWeekRange = () => {
    const startOfWeek = dayjs().startOf("week").format("MM/DD/YYYY");
    const endOfWeek = dayjs().endOf("week").format("MM/DD/YYYY");
    return `${startOfWeek} - ${endOfWeek}`;
  };

  const convertDate = (date: string) => {
    try {
      return new Date(dayjs(new Date(date)).toLocaleString()).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const convertDatePretty = (date: any, time?: boolean) => {
    try {
      const
        currentDate = dayjs(),
        statusDate = dayjs(date);
      if (currentDate.diff(date, "day") === 0) {
        return time ? `Today ${statusDate.format("hh:mm:ss A")}` : `Today`;
      } else if (currentDate.diff(date, "day") === 1) {
        return time ? `Yesterday ${statusDate.format("hh:mm:ss A")}` : `Yesterday`;
      } else {
        return time ? statusDate.format("MMM D, YYYY hh:mm:ss A") : statusDate.format("MMM D, YYYY");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertDateAndTimeStamp = (date: string) => {
    try {
      return new Date(dayjs(date).toLocaleString()).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "numeric"
      });
    } catch (error) {
      console.log(error);
    }
  };


  const getFutureExpDate = (days: number, hours: number, minutes: number) => {
    const futureDate = dayjs(new Date()).add(days, "days").add(hours, "hours").add(minutes, "minutes").local().format();
    return futureDate;
  };

  const dateExpired = (date: any) => {
    const
      startDate = dayjs(new Date(date)).local().format(),
      currentDate = dayjs(new Date()).local().format();
    return dayjs(startDate).isBefore(currentDate);
  };

  const daysBetweenDates = (endDate: any) => {
    const
      sd = dayjs(),
      ed = dayjs(endDate),
      daysRemaining: number = ed.diff(sd, "days");
    return daysRemaining;
  };

  const daysDocumentIsPastDue = (date: any, gracePeriod: number) => {
    try {
      return dayjs(date).add(gracePeriod, "days");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    convertDate,
    getCurrentDate,
    getCurrentYear,
    getArrayOfYears,
    convertDatePretty,
    convertDateAndTimeStamp,
    getFutureExpDate,
    dateExpired,
    daysBetweenDates,
    daysDocumentIsPastDue,
    getArrayOfWeeks,
    getCurrentWeekRange
  };
};