import React, {lazy, Suspense, useEffect, useState} from "react";
import {useApiAuth} from "services/api/useApiAuth";
import {useAtom} from "jotai/index";
import {userInfoAtom} from "services/jotai/auth/msauth";
import urlPaths from "services/api/urlPaths";

const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Stack = lazy(() => import("@mui/material/Stack")),
  UserProfile = lazy(() => import("components/ui/forms/UserProfile")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const Profile = (props: any) => {

  const
    [userInfo] = useAtom<any>(userInfoAtom),
    topperProps: any = {
      title: "My Information",
      inputs: []
    };

  const [userData, setUserData] = useState();
  const {makeRequest} = useApiAuth();
  useEffect(() => {
    makeRequest("GET", `${urlPaths.USER}${userInfo.username}`).then((data: any) => {
      setUserData(data.user);
    }).catch((error) => console.log("error", error));
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Candidates & Issues",
        video: {
          title: "Edit Profile",
          src: "/craft/rest/training/EditProfileScreen.mp4"
        },
        mobile: props.mobile,
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Profile"]
      }} />
      <Stack sx={{margin: "25px 10px 50px 10px", boxShadow: (props?.type === "onboarding") ? 0 : 2, overflow: "hidden"}}
        direction="column"
        spacing={0}>
        <Topper {...{...topperProps}} />
        <UserProfile {...{user: userData}} />
      </Stack>
    </Suspense>
  );
};