import React, {lazy, Suspense, useEffect} from "react";
import {useLocation} from "react-router-dom";
import styled from "@emotion/styled";
import {useAtom} from "jotai";
import {modalContent, modalStateAtom} from "services/jotai/modal";
import {atomWithReset, useResetAtom} from "jotai/utils";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
// Using the custom local storage hook
import {useLocalStorage} from "hooks/useLocalStorage";
import {settings} from "services/jotai/settings/settings";


// import {getStatusIssues} from "services/api/getStatusIssues";
import Modal from "components/ui/modal/index";

dayjs.extend(quarterOfYear);


const
  ReactPlayer = lazy(() => import("react-player/lazy")),
  starterModalState = atomWithReset({title: "", body: "", video: ""});

const GlobalModal = (props: any) => {
  const
    {makeRequest} = useApiAuth(),
    [globalSettings] = useAtom<any>(settings),
    location = useLocation(),
    [modal, setModal] = useAtom(modalStateAtom),
    [content, setContent] = useAtom(modalContent),
    resetModalContent = useResetAtom(starterModalState);

  const
    SuspenseFallback = styled("div")`
      height: 600px;
      width: 100%;
      color: white;
      background-color: black;
    `,
    StyledReactPlayer = styled(ReactPlayer)`
      margin-top: -18px;
      height: 600px;
      width: 100%;
      color: white;
      background-color: black;
    `,
    StyledReactPlayerFallback = styled("div")`
      height: 600px;
      width: 100%;
      color: white;
      background-color: black;
    `,
    StyledModalBody = styled("div")`
      width: 100%;
    `;

  // useEffect(() => {
  //   setContent({
  //     title: "Training Video",
  //     body: "<p style='padding: 10px 50px 50px 50px;'>Curabitur vestibulum aliquam leo. Phasellus consectetuer vestibulum elit. Proin faucibus arcu quis ante. Suspendisse pulvinar, augue ac venenatis condimentum, sem libero volutpat nibh, nec pellentesque velit pede quis nunc. Sed hendrerit." +
  //       "<br><br>" +
  //       "Ut leo. Praesent blandit laoreet nibh. Vestibulum suscipit nulla quis orci. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Sed aliquam ultrices mauris." +
  //       "<br><br>" +
  //       "Quisque rutrum. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Praesent metus tellus, elementum eu, semper a, adipiscing nec, purus. Praesent ac massa at ligula laoreet iaculis. Ut non enim eleifend felis pretium feugiat.</p>",
  //     // <iframe width="700" height="315" src="https://www.youtube.com/embed/g27LsVUqa04?si=zp7UZQ_v08cWzAyh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
  //     video: "https://player.vimeo.com/video/477241362"
  //   });
  //   console.log("modal state atom changed", modal, location);
  // }, [modal]);

  // Only show the modal after the date in the localstorage has passed
  const compareDates = (date: string) => {
    console.log("statuses shouldShowModal", dayjs().isAfter(dayjs(date)));
    return dayjs().isAfter(dayjs(date));
  };

  const updateDate = (setItem: any, values: any) => {
    if (values.intervalLength && values.intervalType && values.intervalLength !== "once" && values.intervalLength !== "every load") {
      // const originalTimezone = values.slice(-6);
      // setItem(dayjs().add(values.intervalLength, values.intervalType).utcOffset(originalTimezone).format("h:mm A").toString());
      setItem(dayjs().add(values.intervalLength, values.intervalType).toString());
    } else {
      setItem(dayjs().toString());
    }
  };
  const getValues = (status: any, item: string) => {
    let showStatus, intervalLength, intervalType;
    console.log("statuses", status, item);
    if (status.priority === "once") {
      // Only Once
      intervalLength = 10;
      intervalType = "years";
      showStatus = item ? false : true;
    } else if (status.priority === "every load") {
      // Every Load
      intervalLength = 1;
      intervalType = "millisecond";
      showStatus = compareDates(item);
    } else if (status.priority === "hour") {
      // Every Hour
      intervalLength = 1;
      intervalType = "hours";
      showStatus = compareDates(item);
    } else if (status.priority === "week") {
      // Every Hour
      intervalLength = 1;
      intervalType = "week";
      showStatus = compareDates(item);
    } else if (status.priority.includes("weeks")) {
      // Every Hour
      intervalLength = status.priority.split(" ")[0];
      intervalType = status.priority.split(" ")[1];
      showStatus = compareDates(item);
    } else if (status.priority === "month") {
      // Every Hour
      intervalLength = 1;
      intervalType = "month";
      showStatus = compareDates(item);
    } else {
      intervalLength = status.priority.split(" ")[0];
      intervalType = status.priority.split(" ")[1];
      showStatus = compareDates(item);
    }
    return {intervalType: intervalType, intervalLength: intervalLength, showStatus: showStatus};
  };

  useEffect(() => {
    const substringsArray = ["logout", "callback?"];
    const match = substringsArray.some((substring: any) => window.location.pathname.includes(substring));


    if (!match && globalSettings) {
      makeRequest("GET", globalSettings?.enviornment?.env?.value === "development" ? urlPaths.STATUS_RELEASE : urlPaths.STATUS_PRODUCTION, null, null).then((data: any) => {

        data.forEach((status: any) => {


          // console.log("statuses", status, `status_${status["_id"]}`, dayjs().toString(), dayjs().add(values.intervalLength, values.intervalType).toString());

          // Logical support for both the pages exact path and contains path
          const logic: { [key: string]: boolean } = {
            contains: window.location.pathname.includes(status.pagePath),
            exact: (!window.location.pathname.includes("?") && window.location.search === "") && window.location.pathname === status.pagePath || (window.location.pathname + window.location.search) === status.pagePath,
          };

          // If there are issues for Signal Craft and the logical path match is true show the modal
          if (status.impactedArea === "signalcraft" && logic[status.pathType] || status.impactedArea === "signalcraft" && status.pathType === "none") {
            const {setItem, getItem, removeItem}: {
              setItem: any,
              getItem: any,
              removeItem: any
            } = useLocalStorage(`status_${status["_id"]}`);

            const values = getValues(status, getItem());

            // Set a value in local storage if one doesn't already exist
            if (!getItem()) {
              updateDate(setItem, values);
              values.showStatus = true;
            }

            if (values.showStatus) {
              setModal(!modal);
              setContent({
                title: status.servicesAffected,
                body: status.message,
                video: ""
              });
            }
            // Update the value in local storage to be future date
            if (values.showStatus) {
              setItem(dayjs().add(values.intervalLength, values.intervalType).toString());
            }
          }
        });
      }).catch((error) => console.log("error", error));
    }

    if (location?.search?.includes("?video=") && location?.search?.includes("?title=")) {
      const params: any = new URLSearchParams(location.search);
      if (params) {
        console.log("params", params.get("video"));
        setModal(!modal);
        setContent({
          title: params.get("title"),
          body: "",
          // body: `<video width="100%" height="500" controls><source src="/craft/rest/training/${params.get("video")}" type="video/mp4">Your browser does not support the video tag.</video>`,
          video: `/craft/rest/training/${params.get("video")}`
        });
      }
    }

  }, [location, globalSettings]);


  return (<Modal {...{
    open: modal,
    handleChange: (e: any) => {
      setModal(!modal);
      resetModalContent;
    },
    title: content.title,
    // buttons: button.modal.modalFooter.modalFooterButtons,
    // submitButtonState: submitButtonState,
    // setModalSubmitState: setModalSubmitState
  }}>
    <Suspense
      fallback={<SuspenseFallback></SuspenseFallback>}>
      {content?.body ? <StyledModalBody dangerouslySetInnerHTML={{__html: content.body}}></StyledModalBody> : <></>}
      {content?.video ?
        <StyledReactPlayer
          url={content?.video}
          controls={true}
          playing={true}
          width={"100%"}
          height={400}
          fallback={<StyledReactPlayerFallback></StyledReactPlayerFallback>}
        />
        : <></>}
    </Suspense>
  </Modal>);
};

export default GlobalModal;