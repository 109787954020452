import React, {lazy, Suspense, useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSnackBar} from "hooks/useSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {MSAUTH_CALLBACK_ROUTE, MSAUTH_VIEW_LOGIN_ROUTE, MSAUTH_VIEW_ROUTE} from "services/constants/routes";
import {authTokenAtom, firstNameAtom, userInfoAtom} from "services/jotai/auth/msauth";
import {CircularProgressProps} from "@mui/material";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {debounce} from "lodash";
import urlPaths from "../../../services/api/urlPaths";

const
  Box = lazy(() => import("@mui/material/Box")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Typography = lazy(() => import("@mui/material/Typography")),
  CircularProgress = lazy(() => import("@mui/material/CircularProgress"));

const SsoLogin = () => {
  const renderCounter = useRef(0);
  renderCounter.current += 1;
  const location = useLocation();
  const navigate = useNavigate();
  const {makeRequest} = useApiAuth();
  const [authToken] = useAtom(authTokenAtom);
  const [userInfo, setUserInfo]: any = useAtom<any>(userInfoAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const {setSnackBar} = useSnackBar();

  const code = new URLSearchParams(location.search).get("code");
  const redirect = `${window.location.protocol}//${window.location.host}${MSAUTH_VIEW_ROUTE}`;

  const fetchData = debounce(async () => {
    try {
      const data = await makeRequest("POST", MSAUTH_CALLBACK_ROUTE, {code, redirect});
      setUserInfo({
        firstName: data?.firstName,
        lastName: data?.lastName,
        username: data?.username,
        isMsUser: data?.isMsUser,
        completedOnboarding: true
      });
      setFirstName(data?.firstName);
      setSnackBar(`Welcome back ${data?.firstName}`, "success");
    } catch (err) {

      setSnackBar(`Error: ` + err, "error");
      navigate(MSAUTH_VIEW_LOGIN_ROUTE);
    }
  }, 300);

  useEffect(() => {
    fetchData();
  }, [code, redirect]);

  useEffect(() => {
    if (authToken) {
      makeRequest("GET", `${urlPaths.USER_ONBOARDING}${userInfo.username}`).then((data: any) => {
        setUserInfo({...userInfo, ...data});
        if (data.completedOnboarding) {
          navigate("/");
        } else {
          navigate("/onboarding");
        }
      }).catch((error) => console.log("error", error));
    }
  }, [authToken]);

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Suspense fallback={<></>}>
        <Box sx={{position: "relative", display: "inline-flex"}}>
          <CircularProgress sx={{height: "130px", width: "130px", margin: "0 auto"}} size="400"/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              component="div"
              color="white"
            >{`Render:  #${Math.round(props.value)}`}</Typography>
          </Box>
        </Box>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<></>}>
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{height: "100vh", width: "100vw", backgroundColor: "#01002C"}}
        spacing={2}
      >
        <CircularProgressWithLabel
          color="primary"
          value={renderCounter.current}
        />
      </Stack>
    </Suspense>
  );
};

export default SsoLogin;
