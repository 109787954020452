import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {v4 as uuidv4} from "uuid";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {useApiAuth} from "services/api/useApiAuth";
import {useSnackBar} from "hooks/useSnackBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Button = lazy(() => import("@mui/material/Button")),
  UploadFileIcon = lazy(() => import("@mui/icons-material/UploadFile")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Status = lazy(() => import("components/ui/table/components/cell/Status").then((module: any) => ({default: module["Status"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccPoliticalComplianceReport = (props: any) => {
  const
    navigate = useNavigate(),
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {makeRequest} = useApiAuth(),
    {setSnackBar} = useSnackBar(),
    {
      getCurrentQuarter,
      getArrayOfQuarters
    } = useQuarter(),
    {
      getCurrentYear,
      getArrayOfYears,
      daysBetweenDates,
      daysDocumentIsPastDue,
      convertDatePretty,
      getArrayOfWeeks,
      getCurrentWeekRange
    } = useDate(),
    [compliant] = useState<any>(false),
    [weeks, setWeeks] = useState<any>(getCurrentWeekRange()),
    [quarter, setQuarter] = useState<any>(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState("My Markets"),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    [showLatest, setShowLatest] = useState<any>(true),
    complianceAtom = requestAtom("politicalCompliance", urlPaths.POLITICAL_ACTIVITY, {
      markets: market === "My Markets" ? allUserMarkets : [market], /*market,*/
      startDate: String(weeks.split(" - ")[0]),
      endDate: String(weeks.split(" - ")[1])
    }, setErrorState),
    [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(complianceState);

  // Get the markets data once from local storage or the server
  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    setComplianceState(complianceAtom);
  }, [weeks, market, allUserMarkets]);


  const
    dismissMatching = async (params: any) => {
      await makeRequest("POST", urlPaths.POLITICAL_MATCHES_DISMISS_MATCHING, {
        csv_id: params.row.csv_id,
        username: userInfo.username
      }, null).then(() => {
        setSnackBar("Matching has been dismised!", "success");
        setComplianceState(complianceAtom);
      }).catch((error) => {
        setSnackBar("Error: " + error, "error");
        console.log("error", error);
      });
    },
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          csv_id: string,
          market: string,
          facility_id: string,
          callsign: string,
          advertiser: string,
          order_type: string,
          upload_process_id: string,
          date_created: string,
          fcc_markets: {
            facId: string,
            service: string,
            calls: string
          }
        }
      }) => {
        return <Link to={`/facility/${params.row.fcc_markets.facId}/`}>
          <Grid container spacing={3}>
            <Grid xs sx={{minHeight: "fit-content", height: "auto", display: "inline-block"}}>
              <div style={{lineHeight: "normal"}}><Box
                component="img"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                loading="lazy"
                sx={{
                  lineHeight: "normal",
                  width: "auto",
                  maxHeight: {xs: 90, md: 90},
                }}
                alt={`${params.row.callsign} Logo`}
                src={`/craft/rest/image?facility_id=${params.row.fcc_markets.facId}`}
              />
              </div>
            </Grid>
            <Grid xs sx={{float: "left"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
                <span><strong>Market:</strong> {params.row.market}</span>
                <span><strong>Callsign:</strong> {params.row.fcc_markets.calls}</span>
                <span><strong>Service:</strong> {params.row.fcc_markets.service}</span>
                <span><strong>Facility ID:</strong> {params.row.fcc_markets.facId}</span>
              </Stack>
            </Grid>
            <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
                <span><strong>Advertiser:</strong> {params.row.advertiser}</span>
                <span><strong>Type:</strong> {params.row.order_type}</span>
                <span><strong>Date Ordered:</strong> {convertDatePretty(params.row.date_created)}</span>
                <span><strong>Date Required:</strong> {convertDatePretty(daysDocumentIsPastDue(params.row.date_created, 5), false)}</span>
                <Status {...{
                  ...props,
                  type: "days",
                  compliant: compliant,
                  mobile: false,
                  value: daysBetweenDates(params.row.date_created)
                }} />
              </Stack>
              <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
                <Stack direction="column" spacing={.5}>
                  <Stack direction="row" sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }} spacing={1}>
                    <Button
                      key={uuidv4()}
                      sx={{
                        minWidth: "fit-content",
                        minHeight: "fit-content",
                        padding: "4px",
                        "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
                      }}
                      onClick={(() => {
                        navigate(`/political-candidates-and-issues/upload/${params.row.csv_id}`);
                        // setModalData(params);
                        // setModalState(!modalState);
                      })}
                    ><UploadFileIcon sx={{
                        color: "#1976d2",
                        fontSize: "1.6rem",
                      }}/> Upload</Button>
                    <Button
                      key={uuidv4()}
                      sx={{
                        minWidth: "fit-content",
                        minHeight: "fit-content",
                        padding: "4px",
                        "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
                      }}
                      onClick={(() => {
                        dismissMatching(params);
                      })}
                    ><RemoveCircleOutlineIcon sx={{
                        color: "#1976d2",
                        fontSize: "1.6rem",
                      }}/> Remove</Button></Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Link>;
      }
    }],
    marketColumns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      }
    }, {
      field: "calls",
      id: "calls",
      headerName: "Callsign",
      flex: 1,
      renderCell: (params: { row: { calls: string, facId: string } }) => {
        return <Link to={`/facility/${params.row.facId}/`}>{params.row.calls}</Link>;
      }
    }, {
      field: "pending_count",
      id: "pending_count",
      headerName: "Pending Orders",
      flex: 1,
      // type: "number",
      // valueGetter: (params: { row: { pending_count: any, completed_count: any, total_orders: any } }) => {
      //   // Ensure the value is a number
      //   const value = params?.row?.pending_count;
      //   return value != null ? Number(value) : 0;
      // },
      renderCell: (params: { row: { pending_count: number, completed_count: any, total_orders: any } }) => {
        const value = params?.row?.pending_count;
        const numericValue = Number(value);
        if (numericValue === 0) {
          return "-";
        }
        return value;
      },
    }, {
      field: "completed_count",
      id: "completed_count",
      headerName: "Completed Orders",
      flex: 1,
      renderCell: (params: { row: { pending_count: any, completed_count: any, total_orders: any } }) => {
        const value = params?.row?.completed_count;
        const numericValue = Number(value);
        if (numericValue === 0) {
          return "-";
        }
        return value;
      },
    }, {
      field: "total_orders",
      id: "total_orders",
      headerName: "Total Orders",
      flex: 1,
      renderCell: (params: { row: { pending_count: any, completed_count: any, total_orders: any } }) => {
        const value = params?.row?.total_orders;
        const numericValue = Number(value);
        if (numericValue === 0) {
          return "-";
        }
        return value;
      },
    }],
    userColumns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      }
    }, {
      field: "user",
      id: "user",
      headerName: "User",
      flex: 1,
      renderCell: (params: { row: { msUser: { displayName: string } } }) => {
        return params.row.msUser.displayName;
      }
    }, {
      field: "matched",
      id: "matched",
      headerName: "Matched Docs",
      flex: 1,
      renderCell: (params: { row: { fcc: { matched: number } } }) => {
        const value = params?.row?.fcc?.matched;
        const numericValue = Number(value);
        if (numericValue === 0) {
          return "-";
        }
        return value;
      }
    }, {
      field: "uploaded",
      id: "uploaded",
      headerName: "Uploaded Docs",
      flex: 1,
      renderCell: (params: { row: { fcc: { uploaded: number } } }) => {
        const value = params?.row?.fcc?.uploaded;
        const numericValue = Number(value);
        if (numericValue === 0) {
          return "-";
        }
        return value;
      }
    }],
    buttonBarButons: any = {
      mobile: props.screen,
      left: [],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/compliance/"
      }]
    },
    marketsTopper: any = {
      title: props.mobile ? "WideOrbit Order Activity" : "WideOrbit Activity By Brand",
      inputs: [{
        label: "Weeks",
        type: "select",
        multiple: false,
        width: "350px",
        options: getArrayOfWeeks(),
        defaultValue: getCurrentWeekRange(),
        state: weeks,
        setState: setWeeks
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: "My Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket("My Markets");
          setWeeks(getCurrentWeekRange());
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    },
    usersTopper: any = {
      title: props.mobile ? "User Activity" : "FCC Activity By User",
      inputs: [{
        label: "Weeks",
        type: "select",
        multiple: false,
        width: "350px",
        options: getArrayOfWeeks(),
        defaultValue: getCurrentWeekRange(),
        state: weeks,
        setState: setWeeks
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: "My Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket("My Markets");
          setWeeks(getCurrentWeekRange());
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Activity Report",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Political Candidates & Issues/matching", "Activity Report"]
      }} />
      <ButtonBar {...buttonBarButons} />
      <Stack spacing={3}>
        <div>
          <Topper {...{...marketsTopper, mobile: props.mobile}} />
          <Table {...{
            tableKey: "marketActivityReport",
            mobile: props.mobile,
            data: data?.brandCount > 0 ? data.brandReport : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: props.mobile ? mobileColumns : marketColumns,
            loading: isPending,
            error: isError,
            errorMessage: data?.brandCount <= 0 ? "No Market Data To Show" : errorState,
            sortBy: "market"
          }} />
        </div>
        <div>
          <Topper {...{...usersTopper, mobile: props.mobile}} />
          <Table {...{
            tableKey: "userActivityReport",
            mobile: props.mobile,
            data: data?.userCount > 0 ? data.userReport : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: props.mobile ? mobileColumns : userColumns,
            loading: isPending,
            error: isError,
            errorMessage: data?.userCount <= 0 ? "No User Data To Show" : errorState,
            sortBy: "market"
          }} />
        </div>
      </Stack>
    </Suspense>
  );
};