import React, {lazy, Suspense, useState} from "react";
import {v4 as uuidv4} from "uuid";
// import Modal from "components/ui/modal/index";
// import Button from "@mui/material/Button";
import {NavLink as Link} from "react-router-dom";
import Stack from "@mui/material/Stack";

const
  Box = lazy(() => import("@mui/material/Box")),
  AppBar = lazy(() => import("@mui/material/AppBar")),
  Toolbar = lazy(() => import("@mui/material/Toolbar")),
  Skeleton = lazy(() => import("@mui/material/Skeleton")),
  Button = lazy(() => import("@mui/material/Button")),
  Modal = lazy(() => import("components/ui/modal/index"));


const ButtonBar = (props: any) => {
  const
    [modalState, setModalState] = useState<boolean>(false),
    [submitButtonState, setModalSubmitState] = useState<boolean>(true),
    updateModalState = () => setModalState(!modalState),
    buttonFunction = (button: ButtonBarModalButton) => {
      return (<div key={uuidv4()}>
        <Button
          key={uuidv4()}
          size="small"
          sx={{
            backgroundColor: "#00bcd4",
            height: "35px",
            maxWidth: "fit-content",
            padding: "0px 25px",
            boxShadow: 0
          }}
          variant="contained"
          onClick={((e: React.MouseEvent) => {
            e.preventDefault();
            updateModalState();
          })}
        >{button.name}</Button>
        <Modal
          key={uuidv4()}
          {...{
            open: modalState,
            data: button.modal.data,
            mobile: props.mobile,
            handleChange: updateModalState,
            title: button.modal.modalTitle,
            buttons: button.modal.modalFooter.modalFooterButtons,
            submitButtonState: submitButtonState,
            setModalSubmitState: setModalSubmitState
          }}>
          <Suspense fallback={<Skeleton animation="wave" variant="rectangular" width={"100%"} height={400}/>}>
            <button.modal.modalForm
              key={uuidv4()}
              {...{
                open: modalState,
                data: button.modal.data,
                mobile: props.mobile,
                handleChange: updateModalState,
                title: button.modal.modalTitle,
                buttons: button.modal.modalFooter.modalFooterButtons,
                submitButtonState: submitButtonState,
                setModalSubmitState: setModalSubmitState
              }}/>
          </Suspense>
        </Modal>
      </div>);
    },

    buttonLink = (button: ButtonBarModalButton) => {
      return (button && button.disabled) ?
        <Button
          size="small"
          sx={{
            backgroundColor: "#00bcd4",
            height: "35px",
            maxWidth: "fit-content",
            padding: "0px 25px",
            boxShadow: 0
          }}
          variant="contained"
          disabled={true}
        >{button.name}</Button>
        : <Link key={uuidv4()} to={`${button.href}`} target={button.location === "external" ? "_blank" : ""}><Button
          size="small"
          sx={{
            backgroundColor: "#00bcd4",
            height: "35px",
            maxWidth: "fit-content",
            padding: "0px 25px",
            boxShadow: 0
          }}
          variant="contained"
        >{button.name}</Button></Link>;
    };
  type Button = ButtonBarModalButton;
  return (
    <Suspense fallback={<></>}>
      <Box sx={{flexGrow: 1, backgroundColor: "transparent"}}>
        <AppBar position="static" elevation={0} sx={{backgroundColor: "transparent"}}>
          <Toolbar>
            <div style={{flexGrow: 1, float: "left"}}>
              <Stack direction="row" spacing={2}>
                {props.left.map((button: Button) => {
                  if (button.type === "modal") {
                    return (buttonFunction(button));
                  } else if (button.type === "link") {
                    return (buttonLink(button));
                  }
                })}
              </Stack>
            </div>
            <div>
              <Stack direction="row" spacing={2}>
                {props.right.map((button: Button) => {
                  if (button.type === "modal") {
                    return (buttonFunction(button));
                  } else if (button.type === "link") {
                    return (buttonLink(button));
                  }
                })}
              </Stack>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </Suspense>
  );
};

export default ButtonBar;