import React, {lazy, Suspense, useEffect, useState} from "react";
import {useStyles} from "./styles.js";
import Skeleton from "@mui/material/Skeleton";
import {GridColDef, gridPageCountSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import {persistTablePagination} from "hooks/persistTablePagination";
import {TablePaginationProps} from "@mui/material/TablePagination";
import Stack from "@mui/material/Stack";


const
  MuiPagination: any = lazy(() => import("@mui/material/Pagination")),
  Paper: any = lazy(() => import("@mui/material/Paper")),
  DataGrid: any = lazy(() => import("@mui/x-data-grid").then((module: any) => ({default: module["DataGrid"]}))),
  LinearProgress: any = lazy(() => import("@mui/material/LinearProgress")),
  GridPagination: any = lazy(() => import("@mui/x-data-grid").then((module: any) => ({default: module["GridPagination"]})));

export const StickyTable = (props: any) => {

  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event: any, newPage: any) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  }

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const
    classes = useStyles(),
    othercolumns: GridColDef[] = props.columns,
    {
      getTableObject,
      resetTable,
      setTablePageSize,
      getTablePageSize,
      setTablePageIndex,
      getTablePageIndex
    } = persistTablePagination(props.tableKey),
    [paginationModel, setPaginationModel] = useState({pageSize: 0, page: 0}),
    getPaginationModel = (data: any) => {
      if (props.tableKey) {
        setPaginationModel({pageSize: data.pageSize, page: data.page});

        setTablePageSize(data.pageSize);
        setTablePageIndex(data.page);
      }
    },
    resetPaginationToOne = (val: any) => {
      setPaginationModel({pageSize: getTablePageSize(), page: 0});
    },
    [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (!props.pending) {
      setPaginationModel({pageSize: Number(getTablePageSize()), page: Number(getTablePageIndex())});
      setShowLoading(true);

      const timeout = setTimeout(() => {
        setShowLoading(false);
      }, 1000);

      if (props.data && props.data.length >= 1) {
        setShowLoading(false);
        clearTimeout(timeout);
      }

      return () => clearTimeout(timeout);
    }
  }, [props.data]);

  const maxMinHeight = (Number(props.data.length < 4 && props.fileCount ? props.data.length * 1.3 + Number(props.fileCount * 0.35) : props.data.length < 4 ? props.data.length * 1.3 : props.data.length) * 90) < 800 ? Number(props.data.length < 4 && props.fileCount ? props.data.length * 1.3 + Number(props.fileCount * 0.35) : props.data.length < 4 ? props.data.length * 1.3 : props.data.length) * 90 : 800;

  return (
    <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={400}/>}>
      <Paper sx={{
        width: "100%",
        // minHeight: props.minHeight ? `${props.minHeight}px` : undefined,
        height: "fit-content",
        // maxHeight: props.maxHeight ? `${props.maxHeight}px` : undefined,
        // overflow: props.maxHeight ? "scroll" : undefined,
        padding: props?.mobile ? "0 10px" : undefined
      }}
      elevation={props?.mobile ? 3 : 1}>
        {/*<Topper props={props as any} />*/}
        <DataGrid
          loading={props.loading ? props.loading : showLoading}
          // rowHeight={100}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{height: "inherit"}}
              >{props.error ? props.errorMessage : props.data.length <= 0 ? props?.errorMessage : "No Rows"}</Stack>
            ),
            pagination: CustomPagination,
          }}
          // rowCount={props.data.length}
          rows={props.data}
          columns={props.columns}
          pageSizeOptions={[5, 10, 15, 20, 50, 100]}
          // stickyHeader={false}
          // stickyFooter={true}
          density={"compact"}
          rowHeight={60}
          scrollbarSize={15}
          autosizeOnMount={false}
          disableAutosize={true}
          disableMultipleRowSelection={true}
          disableRowSelectionOnClick={true}
          isRowSelectable={() => false}
          // rowSelectionModel={null}
          // autoHeight={true}
          disableVirtualization={true}
          disableSelectionOnClick={true}
          onPaginationModelChange={getPaginationModel}
          paginationModel={paginationModel}
          onSortModelChange={(e: any) => resetPaginationToOne(0)}
          onFilterModelChange={(e: any) => resetPaginationToOne(0)}
          initialState={{
            pagination: {
              paginationModel: {
                page: props.tableKey ? getTablePageIndex() : 0,
                pageSize: props.tableKey && getTablePageSize() ? getTablePageSize() : props.pageSize ? props.pageSize : 10,
              },
            },
            sorting: {
              sortModel: [{field: (props.sortBy ? props.sortBy : "upload_date"), sort: "desc"}],
            }
          }}
          // autoHeight
          // getRowId={() => Math.floor(Math.random() * 100000000)}
          // getRowId={(row: any) => props?.selector ? row[props.selector].toString() + uuidv4().toString() + uuidv4().toString() : row["facility_id"].toString() + uuidv4().toString() + uuidv4().toString()}
          // getRowId={(row: any) => row.date + uuidv4().toString()}
          // autoHeight={true}
          // autoPageSize={true}
          // experimentalFeatures={false}
          // checkboxSelection
          // stickyHeader={true}
          // headerClassName={classes.tableHeader}
          sx={{
            // minHeight: `${props.height}px`,
            // minWidth: "500px",
            // boxShadow: 5,
            // padding: 2,
            boxShadow: props?.mobile ? 3 : 1,
            border: 0,
            backgroundColor: "transparent",
            borderColor: "transparent",
            "& .MuiDataGrid-cell:focus": {
              outline: "none"

            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none"
            },
            "& .MuiDataGrid-main": {
              // maxHeight:
              // overflow: "scroll"
              // minHeight: "400px",
              // maxHeight: "800px",
              // getTablePageSize() ? getTablePageSize() : props.pageSize
              minHeight: props.minHeight && props.minHeight === "scaleWithDataSize" && !props?.mobile && props.data && props.data.length ? `${maxMinHeight}px` :
                props.minHeight && !props.minHeight && !props?.mobile ? `${props.minHeight}` :
                  props.data && !props.minHeight && getTablePageSize() && props.data.length >= getTablePageSize() ? `${(getTablePageSize() + 1.1) * 50}px` :
                    props.data && !props.minHeight && props.pageSize && props.data.length >= props.pageSize ? `${(props.pageSize + 1.1) * 50}px` :
                      props.data && !props.minHeight && props.data.length === 1 ? `${(props.data.length + 1) * 50}px` :
                        props.data && !props.minHeight && ((props.data.length + 1.8) * 50) <= 400 ? `${(props.data.length + 1.8) * 50}px` :
                          props.minHeight ? props.minHeight : "400px",

              // minHeight: props.minHeight ? `${props.minHeight}` : "max-content",
              // height: "fit-content",
              height: "auto",
              maxHeight: props.maxHeight && !props?.mobile ? `${props.maxHeight}` : "800px",
              // maxHeight: props.maxHeight ? `${props.maxHeight}` :
              //   props.data && getTablePageSize() && props.data.length >= getTablePageSize() ? `${(getTablePageSize() + 1.3) * 50}px` :
              //     props.data && props.pageSize && props.data.length >= props.pageSize ? `${(props.pageSize + 1.3) * 50}px` :
              //       props.data && ((props.data.length + 1.3) * 50) <= 800 ? `${(props.data.length + 1.3) * 50}px` :
              //         "800px",
              // overflow: "scroll"
            },
            "& .MuiDataGrid-virtualScroller": {
              minHeight: props.minHeight && props.minHeight === "scaleWithDataSize" && !props?.mobile && props.data && props.data.length ? `${maxMinHeight}px` :
                props.data && !props.minHeight && getTablePageSize() && props.data.length >= getTablePageSize() ? `${(getTablePageSize() + 1.1) * 50}px` :
                  props.data && !props.minHeight && props.pageSize && props.data.length >= props.pageSize ? `${(props.pageSize + 1.1) * 50}px` :
                    props.data && !props.minHeight && props.data.length === 1 ? `${(props.data.length + 1) * 50}px` :
                      props.data && !props.minHeight && ((props.data.length + 1.8) * 50) <= 400 ? `${(props.data.length + 1.8) * 50}px` :
                        props.minHeight ? props.minHeight : "400px",           // minHeight: props.minHeight ? `${props.minHeight}` : "400px",
              height: "auto",
              maxHeight: props.maxHeight && !props?.mobile ? `${props.maxHeight}` : "800px",
              // maxHeight: props.maxHeight ? `${props.maxHeight}` :
              //   props.data && getTablePageSize() && props.data.length >= getTablePageSize() ? `${(getTablePageSize() + 1.3) * 50}px` :
              //     props.data && props.pageSize && props.data.length >= props.pageSize ? `${(props.pageSize + 1.3) * 50}px` :
              //       props.data && ((props.data.length + 1.3) * 50) <= 800 ? `${(props.data.length + 1.3) * 50}px` :
              //         "800px",
              overflow: "scroll"
              // minHeight: "400px",
              // maxHeight: "800px",
              // minHeight: props.minHeight ? `${props.minHeight}px` : undefined,
              // height: "fit-content",
              // maxHeight: props.maxHeight ? `${props.maxHeight}px` : undefined,
              // overflow: "scroll"
            },
            "& .MuiDataGrid-virtualScrollerRenderZone": {
              // marginBottom: "10px",
              // transform: "none !important"
              // paddingBottom: "20px",
              // minHeight: `${props.height}px`,
            },
            // "& .MuiDataGrid-virtualScroller": {
            //   minHeight: `${props.height}px`,
            // },
            "& .MuiDataGrid-menuIcon, svg[data-testid='TripleDotsVerticalIcon'], .MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller, .MuiDataGrid-scrollbarFiller--header": {
              ...classes.tableHeader
            },
            "& .MuiDataGrid-row, .documents": {
              // height: "auto !important",
              // maxHeight: "80px !important",
              // paddingTop: "5px",
              // paddingBottom: "5px"
              // minHeight: "100px !important",
            },
            // Header
            "& .MuiDataGrid-columnHeaders > div[aria-rowindex='1']": {
              backgroundColor: "#e0f7fa"
            },
            // Header text
            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: "500",
              // fontWeight: "600 !important",
              color: "#424242 !important",
              lineHeight: "1.5rem",
              fontSize: "1.1rem",
              fontVariant: "all-small-caps",
              fontStyle: "uppercase",
              letterSpacing: "0.01071em",
              verticalAlign: "inherit",
              textAlign: "left",
              borderBottom: "none"
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#f5f5f5",
            },
            // "& .MuiDataGrid-container--bottom": {
            //   marginBottom: "50px"
            // }
            "& .MuiDataGrid-cell": {
              // marginTop: props?.mobile ? "15px" : undefined,
              paddingTop: props?.mobile ? "30px" : "5px",
              paddingBottom: props?.mobile ? "15px" : "5px",
              // display: "flex",
              display: "flex",
              height: props?.mobile ? "auto" : "auto",
              minHeight: props?.mobile ? "fit-content" : undefined,
              flexDirection: "row",
              alignItems: "center",

              // justifyContent: "center",
              // verticalAlign: "middle !important",
              // alignSelf: "center",
              // lineHeight: "normal",
              // lineHeight: "60px"
              // height: "max-content",
            },
            "& .MuiDataGrid-filler": {
              // height: "500px",
              display: "none"
            },
            // "& .MuiDataGrid-cell:hover": {
            //   color: "primary.main",
            // },
            "& .MuiDataGrid-row": {
              height: props?.mobile ? undefined : "none !important",
              maxHeight: "none !important",
              minHeight: props?.mobile ? undefined : "none !important"
            },
            "& .MuiGrid-root": {
              marginLeft: props?.mobile && !props?.removeMobileMargin ? "0px !important" : undefined
            }
            // "& .MuiDataGrid-cell:focus": {
            //   outline: "none"
            // }
          }}
        />
      </Paper>
    </Suspense>
  );
};