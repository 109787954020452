import React, {lazy, Suspense, useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ModalFooter from "components/ui/modal/components/ModalFooter";
import MultiSelect from "components/ui/forms/components/fields/MultiSelect";
import TextField from "components/ui/forms/components/fields/TextField";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useMainSnackBar} from "hooks/useMainSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import {allTheFacilitiesData} from "services/jotai/requests/requests";
import {useCallsignConverter} from "hooks/useCallsignConverter";
import {cachedRequests} from "services/api/cachedRequests";
import urlPaths from "services/api/urlPaths";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {Accordion, AccordionDetails, AccordionSummary, Button, Chip} from "@mui/material";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {Brand} from "../table/components/cell/Brand";
import {bulkUploadCsvIds} from "services/jotai/political/political";
import {CollapseTable} from "../table/collapseTable";
import MediaUploaderDropZone from "./components/fields/MediaUploaderDropZoneMultiUseage";
import {v4 as uuidv4} from "uuid";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Skeleton from "@mui/material/Skeleton";
import {settings} from "services/jotai/settings/settings";


interface IFormInputs {
  Markets: any | string | string[],
  Callsigns: string[],
  PdfFile: any,
  Files: any[],
  Type: string,
  OfficeType: string,
  Office: string,
  Years: number | null,
  Quarters: any,
  CandidateOrIssueName: string,
  CustomFolder: string,
  CustomFolders: string[],
  FilePath: string[],
  ExistingFolders: string
}

interface Market {
  name: string;
  id: string | number;
}

const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]})));
export default function FccPoliticalCandidatesAndIssuesMultiUploadForm(props: any) {
  const
    {makeRequest} = useApiAuth(),
    [globalSettings] = useAtom<any>(settings),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {
      getCurrentQuarter
    } = useQuarter(),
    navigate = useNavigate(),
    [bulkCsvIds] = useAtom(bulkUploadCsvIds),
    {
      getCurrentYear,
      getArrayOfYears,
      convertDatePretty
    } = useDate(),
    {cachedMarketsData} = cachedRequests(),
    {getCallsign} = useCallsignConverter(),
    [allFacilitiesData] = useAtom(allTheFacilitiesData),
    [resetPagination, setResetPagination] = useState(0),
    [submitted, setSubmitted] = useState(false),
    {makePoliticalFileUploadRequest} = useApiAuth(),
    {register, resetField, handleSubmit, control, setValue: parentSetValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        Markets: [],
        Callsigns: [],
        Files: [],
        Years: getCurrentYear(),
        Quarters: `Q${getCurrentQuarter()}`,
        ExistingFolders: "Existing FCC Folders"
      },
    }),
    year: any = useWatch({control, name: "Years"}),
    files = useWatch<any>({control, name: "Files"}),
    [marketNames, setMarketNames] = useState<any>([]),
    [marketCallsigns] = useState([]),
    yearOptions = getArrayOfYears(),
    {setMainSnackBar} = useMainSnackBar(),
    [newCallsigns, setNewCallsigns] = useState<any>([]),
    passesValidation = () => {
      if (Object.keys(files).length > 0) {
        const allValid = Object.keys(files).every((fileId: any) => {
          console.log("validation", files[fileId].validates);
          return files[fileId].validates === true;
        });
        return allValid;
      } else {
        return false;
      }
    },
    resolver = async (data: any) => {
      const allValid = passesValidation();
      console.log("validation resolver", allValid);

      if (allValid) {
        const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

        console.log("submit data", data.data, data.files);
        const batchId = uuidv4().toString().replace(/-/gi, "");
        setSubmitted(true);
        let filesSubmitted = 0;
        await Object.keys(data.files).map(async (csv_id: any) => {
          console.log("csv_id", csv_id, files[csv_id].facilityId);
          let index = 0;
          if (data.files[csv_id].cleanFiles) {
            for await (const file of data.files[csv_id].cleanFiles) {
              await console.log("submit data cleanFile", {
                status: file.status,
                file: file?.file,
                csv: files[csv_id],
                data: data.files[csv_id]
              });
              const filePath = data.files[csv_id].filePath && data.files[csv_id].customFolders ? data.files[csv_id].filePath.join("/").toString() + "/" + data.files[csv_id].customFolders.join("/").toString() : data.files[csv_id].filePath ? data.files[csv_id].filePath.join("/").toString() : null;
              const correctedFilePath = globalSettings?.enviornment?.env?.value === "development" ? filePath.replace(data.Years.toString(), "2014") : filePath;
              // Add the new document to the "craft_fccpoliticalissues" collection
              await makePoliticalFileUploadRequest("POST", urlPaths.POLITICAL_DOCUMENT_UPLOAD, {
                ui_batch_id: batchId,
                fcc_path: correctedFilePath,
                year: globalSettings?.enviornment?.env?.value === "development" ? "2014" : year?.toString(),
                candidate_campaign_name: data.files[csv_id].candidateOrIssueName ? data.files[csv_id].candidateOrIssueName.includes("/") ? data.files[csv_id].candidateOrIssueName.split("/")[0] : data.files[csv_id].candidateOrIssueName : String("Terms and Disclosures"),
                facility_id: data.files[csv_id].facilityId,
                file: file?.file,
                candidate_type: data.files[csv_id].type,
                region: data.files[csv_id].officeType ? data.files[csv_id].officeType : "",
                office_name: data.files[csv_id].office ? data.files[csv_id].office : "",
                custom_folder_path: data.files[csv_id].customFolders ? data.files[csv_id].customFolders : [],
              }, null).then(async (response: any) => {
                if (response) {
                  parentSetValue(`Files.${csv_id}.cleanFiles[${index}].status`, "complete", {shouldValidate: true});
                  index++;
                  filesSubmitted++;
                  if (response?.items[0]?.file_id) {
                    const resp = response?.items[0];
                    // Add the new array item to the existing possibleMatches array
                    await makeRequest("POST", urlPaths.POLITICAL_MATCHES_ADD, {
                      file_id: resp.file_id,
                      csv_id: csv_id,
                      username: userInfo.username,
                      facility_id: resp.facility_id,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      callsign: allFacilitiesData[resp?.facility_id]?.callsign,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      market_name: allFacilitiesData[resp?.facility_id]?.market,
                      name: file?.file?.name,
                      candidate_campaign_name: data.files[csv_id].candidateOrIssueName.toString(), /* data.CandidateOrIssueName.toString(), */
                      year: resp.year,
                      quarter: Number(resp.date_based_quarter),
                      date_based_quarter: Number(resp.date_based_quarter),
                      path: data.files[csv_id].filePath.join("/").toString(),
                    }, null).then((data: any) => {
                      console.log("Adding a new match", data);
                    }).catch((error) => {
                      console.log("error", error);
                    });
                    await sleep(1000);
                    // Add the new document to the political matches collection craft_fccpoliticalissuesmatched
                    await makeRequest("POST", urlPaths.POLITICAL_MATCHES_SET, null, {
                      csv_id: csv_id,
                      file_id: resp.file_id,
                      username: userInfo.username,
                      method: "upload",
                      form: "bulk"
                    }).catch((error) => {
                      console.log("error", error);
                    });
                  }
                }
              }).catch((error: any) => {
                setMainSnackBar("error", `Error adding document: (${file?.file?.name})`, "error");
                console.log("error", error);
              });
            }
          }
        });
        await sleep(4000);
        await setMainSnackBar("success", `${filesSubmitted} new document successfully submitted!`, "success");
        await navigate(`/political-candidates-and-issues/queue/${batchId}`);
      } else {
        setMainSnackBar("error", `Please ensure images have been added to each order and that upload details have been set before proceeding`, "error");
      }
    },
    onSubmit: SubmitHandler<IFormInputs> = (data: any) => resolver({data: data, files: files}),
    [allCsvData, setAllCsvData] = useState<any>([]),
    getCsvData = () => {
      if (bulkCsvIds && bulkCsvIds.length >= 0) {
        let ids;
        if (bulkCsvIds.length >= 1) {
          ids = bulkCsvIds.map((item: any, index: number) => `csv_ids[${index}]=${encodeURIComponent(item)}`).join("&");
        } else {
          ids = bulkCsvIds.map((item: any, index: number) => `csv_ids[${index}]=${encodeURIComponent(item)}`);
        }
        return makeRequest("GET", `${urlPaths.POLITICAL_MATCHES_BY_IDS}?${ids}`, null, null).then(async (response: any) => {
          if (response) {
            // await setAllCsvData(response.items);
            return response.items;
          }
        }).catch((error: any) => {
          return error;
        });
      }
      return null;
    };

  useEffect(() => {
    setResetPagination(resetPagination + 1);
  }, []);

  // Populate data once the API returns the rest markets data
  useEffect(() => {
    cachedMarketsData();
  }, []);

  // Redirect the user after 3 seconds if there are no bulkCsvIds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (bulkCsvIds.length <= 0) {
        navigate("/political-candidates-and-issues/matching/");
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [bulkCsvIds]);

  // Update files data when new callsigns are selected
  useEffect(() => {
    if (allCsvData) {
      const data: any = {};
      allCsvData.map((csv: any, index: number) => {
        data[csv.csv_id] = {
          id: `${csv.id}`,
          csv_id: csv.id,
          facilityId: csv.fcc_markets.facId,
          files: null,
          cleanFiles: null,
          year: null,
          type: null,
          filePath: null,
          customFolders: null,
          customFolder: null,
          officeType: null,
          office: null,
          candidateOrIssueName: null,
          validates: null,
          existingFolders: null,
        };
      });

      parentSetValue("Files", data);
    }
  }, [allCsvData]);

  useEffect(() => {
    if (bulkCsvIds && bulkCsvIds.length > 0) {
      getCsvData()?.then((resp: any) => {
        console.log("resp", resp);
        const allMarkets = new Set();
        const allCallsigns = new Set();
        resp.map((item: any) => {
          allMarkets.add({label: item.fcc_markets.market, id: item.fcc_markets.market});
          allCallsigns.add({
            label: item.fcc_markets.calls,
            id: item.fcc_markets.calls,
            facility_id: item.fcc_markets.facId,
          });
        });
        setMarketNames(Array.from(allMarkets));
        parentSetValue("Markets", Array.from(allMarkets).map((market: any) => market.id));
        setNewCallsigns(Array.from(allCallsigns));
        parentSetValue("Callsigns", Array.from(allCallsigns).map((callsign: any) => callsign.facility_id));


        setAllCsvData(resp);
      }).catch(console.error);
    }
  }, [bulkCsvIds]);

  // Add csv_ids to each of the items inside the files object
  useEffect(() => {
    if (files) {
      allCsvData.map((csv: any) => {
        const temp = files;
        if (temp[csv.csv_id]?.csv_id) {
          temp[csv.csv_id].csv_id = csv.csv_id;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          parentSetValue(`Files`, temp, {shouldValidate: true});
        }
      });
      console.log("allCsvData", allCsvData);
    }
  }, [allCsvData]);

  useEffect(() => {
    if (props.submit) {
      console.log("trying to submit", props.submit);
      trigger().then((isValid) => {
        if (isValid) {
          handleSubmit(onSubmit)(); // Submits the form if all validations pass
        }
      });
    }
  }, [props.submit]);

  useEffect(() => {
    let count = 0;
    if (files && Object.keys(files)?.length > 0) {
      Object.keys(files)?.forEach((facility: any) => {
        if (files[facility].files) {
          count += files[facility].files?.length;
        }
      });
      // setFileCount(count);
    }
    console.log("files", files);
  }, [files]);


  const SelectedFiles = (p: any) => {
    return <Grid container spacing={2} sx={{margin: "0 auto", maxWidth: "900px"}}>
      <Grid item xs={6} md={12}>
        <Stack direction="column"
          spacing={0}>{files && files[p.props?.csv_id]?.cleanFiles?.length >= 0 ? files[p.props?.csv_id]?.cleanFiles.map((file: any) => {
            return <Stack key={uuidv4()} direction="row" spacing={0}>
              <Grid item xs={6} md={10}>
                <span key={uuidv4()} style={{overflow: "hidden", textOverflow: "ellipsis"}}>{file.file.name}</span>
              </Grid>
              <Grid item xs={6} md={2}>
                {(file.status === null && !submitted) ? <IconButton
                  key={uuidv4()}
                  sx={{margin: "0 auto"}}
                  onClick={() => {
                    console.log("deleting stuff", file);
                    // Logic to remove deleted file from the uploads data
                    const newVals: any = [];
                    const cleanFiles: any = [];
                    files[p.props?.csv_id].files.map((f: any) => {
                      if (f.name !== file.file.name) {
                        newVals.push(f);
                      }
                    });
                    files[p.props?.csv_id].cleanFiles.map((f: any) => {
                      if (f.file.name !== file.file.name) {
                        cleanFiles.push(f);
                      }
                    });
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setValue(`Files.${p.props.csv_id}.files`, newVals, {shouldValidate: true});
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setValue(`Files.${p.props.csv_id}.cleanFiles`, cleanFiles, {shouldValidate: true});
                  }}>
                  <DeleteOutlineIcon sx={{
                    color: "#1976d2",
                    fontSize: "1.1rem",
                    margin: "0 auto"
                  }}/>
                </IconButton> :
                  (file.status === null && submitted) ?
                    <IconButton key={uuidv4()} sx={{margin: "0 auto"}}><CircleIcon key={uuidv4()} sx={{
                      color: "#ed6c02",
                      margin: "0 auto"
                    }}/></IconButton> :
                    (file.status === "complete" && submitted) ?
                      <IconButton key={uuidv4()} sx={{margin: "0 auto"}}><CircleIcon key={uuidv4()} sx={{
                        color: "green",
                        margin: "0 auto"
                      }}/></IconButton> :
                      <></>}
              </Grid>
            </Stack>;
          }) : <></>}
        </Stack>
      </Grid>
    </Grid>;
  };

  const UploadDetails = (p: any) => {
    const {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        Type: files[p.props.csv_id].type,
        OfficeType: files[p.props.csv_id].officeType,
        Office: files[p.props.csv_id].office,
        CandidateOrIssueName: files[p.props.csv_id].candidateOrIssueName,
        FilePath: files[p.props.csv_id].filePath,
        ExistingFolders: files[p.props.csv_id].existingFolders ? files[p.props.csv_id].existingFolders : "Existing FCC Folders"
      },
    }),
      single_type = useWatch({control, name: "Type"}),
      single_office_type = useWatch({control, name: "OfficeType"}),
      single_office = useWatch({control, name: "Office"}),
      single_name = useWatch({control, name: "CandidateOrIssueName"}),
      existingFolders = useWatch({control, name: "ExistingFolders"}),
      temp = files,
      [fccFilePaths, setFccFilePaths] = useState<string[]>([]),
      getFccFilePaths = async () => {
        console.log("p.props.facility_id", p.props);
        if (p.props.market) {
          const options: any = {
            year: year,
            market: [p.props.market]
          };
          p.props?.fcc_markets?.facId ? options.facility_id = [p.props.fcc_markets.facId] : undefined;
          single_type ? options.type = single_type : undefined;
          single_office_type && options.type === "Candidate" ? options.region = single_office_type : undefined;
          single_office && options.type === "Candidate" ? options.office = single_office : undefined;
          await makeRequest("GET", urlPaths.POLITICAL_FOLDERS, null, options).then((response: any) => {
            if (response) {
              setFccFilePaths(response.items);
            }
          }).catch((error: any) => {
            console.log("error", error);
          });
        }
      };

    useEffect(() => {
      temp[p.props.csv_id].type = single_type;
      temp[p.props.csv_id].officeType = single_office_type;
      temp[p.props.csv_id].office = single_office;
      temp[p.props.csv_id].candidateOrIssueName = single_name;
      temp[p.props.csv_id].existingFolders = existingFolders;

      if (single_type === "Candidate" && single_office_type === "Federal") {
        if (single_office_type && single_office && single_name) {
          temp[p.props.csv_id].validates = true;
        } else {
          temp[p.props.csv_id].validates = false;
        }
        temp[p.props.csv_id].filePath = [String(year), single_office_type ? single_office_type.toString() : "", single_office ? single_office.toString() : "", single_name ? single_name.toString() : ""];
      }
      // Office type === Local OR State
      else if (single_type === "Candidate" && single_office_type === "Local" || single_type === "Candidate" && single_office_type === "State") {
        if (single_office_type && single_name) {
          temp[p.props.csv_id].validates = true;
        } else {
          temp[p.props.csv_id].validates = false;
        }
        temp[p.props.csv_id].filePath = [String(year), single_office_type ? single_office_type.toString() : "", single_name ? single_name.toString() : ""];
      }
      // officeType === "Non-Candidate Issue Ads"
      else if (single_type === "Non-Candidate Issue Ads") {
        if (single_type && single_name) {
          temp[p.props.csv_id].validates = true;
        } else {
          temp[p.props.csv_id].validates = false;
        }
        temp[p.props.csv_id].filePath = [String(year), single_type ? single_type.toString() : "", single_name ? single_name.toString() : ""];
      }


      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(`Files.${p.props.csv_id}`, temp[p.props.csv_id], {shouldValidate: true});

      if (existingFolders === "Existing FCC Folders") {
        getFccFilePaths();
      }

    }, [single_type, single_office_type, single_office, single_name, existingFolders]);

    return <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
      <MultiSelect {...{
        name: "Type",
        label: "Type",
        multiSelect: false,
        validationMessage: "Select a type",
        options: ["Candidate", "Non-Candidate Issue Ads"], /*"Terms and Disclosures"*/
        required: true,
        control: control
      }}  />
      {single_type === "Candidate" ? <MultiSelect {...{
        name: "OfficeType",
        label: "Region",
        multiSelect: false,
        validationMessage: "Select a region",
        options: ["Federal", "Local", "State"],
        required: true,
        control: control
      }}  /> : <></>}
      {single_type === "Candidate" && single_office_type === "Federal" ?
        <MultiSelect {...{
          name: "Office",
          label: "Political Office",
          multiSelect: false,
          validationMessage: "Select an office",
          options: ["President", "US House", "US Senate"],
          required: true,
          control: control
        }}  /> : <></>}
      {single_type && single_type !== "Terms and Disclosures" ? <MultiSelect {...{
        name: "ExistingFolders",
        label: `Folder Type`,
        multiSelect: false,
        validationMessage: `Please include a ${single_type} name`,
        options: ["Existing FCC Folders", "New FCC Folder"],
        required: true,
        control: control
      }}  /> : <></>}

      {existingFolders === "Existing FCC Folders" && single_type && single_type !== "Terms and Disclosures" ?
        <MultiSelect {...{
          name: "CandidateOrIssueName",
          label: `${single_type} Name`,
          multiSelect: false,
          validationMessage: `Please include a ${single_type} name`,
          options: fccFilePaths.length > 0 ? fccFilePaths : ["No Existing Folders To Show"],
          required: true,
          control: control
        }}  /> : <></>}
      {existingFolders === "New FCC Folder" && single_type && single_type !== "Terms and Disclosures" ? <TextField {...{
        name: "CandidateOrIssueName",
        label: `${single_type} Name`,
        validationMessage: `Please include a ${single_type} name`,
        required: true,
        spacesAllowed: true,
        specialCharactersAllowed: true,
        control: control
      }} /> : <></>}

      {/*{single_type && single_type !== "Terms and Disclosures" ? <TextField {...{*/}
      {/*  name: "CandidateOrIssueName",*/}
      {/*  label: `${single_type} Name`,*/}
      {/*  validationMessage: `Please include a ${single_type} name`,*/}
      {/*  required: true,*/}
      {/*  spacesAllowed: true,*/}
      {/*  specialCharactersAllowed: true,*/}
      {/*  control: control*/}
      {/*}} /> : <></>}*/}
    </Stack>;
  };

  const CustomFolderPath = (p: any) => {
    const {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        FilePath: files[p.props.csv_id].filePath ? files[p.props.csv_id].filePath : [],
        CustomFolders: files[p.props.csv_id].customFolders ? files[p.props.csv_id].customFolders : [],
        CustomFolder: files[p.props.csv_id].customFolder ? files[p.props.csv_id].customFolder : "",
        Type: files[p.props.csv_id].type ? files[p.props.csv_id].type : null,
        OfficeType: files[p.props.csv_id].officeType ? files[p.props.csv_id].officeType : null,
        Office: files[p.props.csv_id].office ? files[p.props.csv_id].office : null,
        CandidateOrIssueName: files[p.props.csv_id].candidateOrIssueName ? files[p.props.csv_id].candidateOrIssueName : "",
        ExistingFolders: files[p.props.csv_id].existingFolders ? files[p.props.csv_id].existingFolders : ""
      },
    }),
      filePath = useWatch({control, name: "FilePath"}),
      single_custom_folders = useWatch({control, name: "CustomFolders"}),
      single_custom_folder = useWatch({control, name: "CustomFolder"}),
      single_type = useWatch({control, name: "Type"}),
      single_office_type = useWatch({control, name: "OfficeType"}),
      single_office = useWatch({control, name: "Office"}),
      single_name = useWatch({control, name: "CandidateOrIssueName"}),
      existingFolders = useWatch({control, name: "ExistingFolders"}),
      [fp, setFp] = useState<any>([]);

    // Update the file path component when various details change inside the UI and send the final path to the backend
    useEffect(() => {

      setValue("FilePath", []);


      const allItems = single_name ? single_name.split("/") : "";
      const [...singleItems]: any = allItems;

      // officeType === Federal
      if (single_type === "Candidate" && single_office_type === "Federal") {
        if (single_custom_folders && single_custom_folders.length > 0) {
          setValue("FilePath", [String(year), single_office_type ? single_office_type.toString() : "", single_office ? single_office.toString() : "", ...singleItems, ...single_custom_folders]);
        } else {
          setValue("FilePath", [String(year), single_office_type ? single_office_type.toString() : "", single_office ? single_office.toString() : "", ...singleItems, ...single_custom_folders]);
        }
      }
      // Office type === Local OR State
      else if (single_type === "Candidate" && single_office_type === "Local" || single_type === "Candidate" && single_office_type === "State") {
        if (single_custom_folders && single_custom_folders.length > 0) {
          setValue("FilePath", [String(year), single_office_type ? single_office_type.toString() : "", ...singleItems, ...single_custom_folders]);
        } else {
          setValue("FilePath", [String(year), single_office_type ? single_office_type.toString() : "", ...singleItems]);
        }
      }
      // officeType === "Non-Candidate Issue Ads"
      else if (single_type === "Non-Candidate Issue Ads") {
        if (single_custom_folders && single_custom_folders.length > 0) {
          setValue("FilePath", [String(year), single_type ? single_type.toString() : "", ...singleItems, ...single_custom_folders]);
        } else {
          setValue("FilePath", [String(year), single_type ? single_type.toString() : "", ...singleItems]);
        }
      }
      setFp(filePath);

    }, [year, single_type, single_office_type, single_office, single_name, single_custom_folders]);

    useEffect(() => {
      const temp = files;
      temp[p.props.csv_id].customFolders = single_custom_folders;
      temp[p.props.csv_id].customFolder = single_custom_folder;

      console.log("single_custom_folders", single_custom_folders, single_custom_folder);


      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(`Files`, temp, {shouldValidate: true});
    }, [single_custom_folders, single_custom_folder]);

    useEffect(() => {
      setFp(filePath);
      console.log("test", filePath, single_name);
      console.log("test2", filePath, single_custom_folders, single_custom_folder);
    }, [filePath]);

    return <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={200}/>}>
      <>{filePath && filePath.length > 0 ? <Stack direction="column" spacing={2}>
        <Breadcrumbs
          sx={{fontFamily: "arial", fontSize: "13px", fontWeight: "200", color: "black"}}
          separator="/"
          aria-label="breadcrumb">
          {fp.map((item: any) => {
            return (<span key={item}>{item}</span>);
          })}
        </Breadcrumbs>
        <Stack direction="column" spacing={2}>
          {single_custom_folders?.length > 0 ? <><p style={{
            fontWeight: "100",
            fontSize: "13px",
            margin: "5px 0px -10px 0px",
          }}><strong>Custom Folders:</strong></p>
          <Stack direction="row" spacing={2}>
            {single_custom_folders?.map((folder: any) => {
              return <Chip
                key={uuidv4()}
                label={folder}
                variant="outlined"
                onClick={() => console.log(folder)}
                onDelete={() => {
                  const index = single_custom_folders.findIndex(item => item === folder);
                  index !== -1 && single_custom_folders.splice(index, 1);
                  setValue("CustomFolders", [...single_custom_folders]);
                }}
              />;
            })}
          </Stack>
          </> : <></>}
        </Stack>
        <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
          <TextField {...{
            name: "CustomFolder",
            label: `Custom Folder Name`,
            validationMessage: `Please include a folder name`,
            required: false,
            spacesAllowed: true,
            specialCharactersAllowed: true,
            control: control
          }} />
          <Button variant="contained" disableElevation size={"small"}
            onClick={() => {
              if (single_custom_folder !== "") {
                setValue("CustomFolders", [...single_custom_folders, single_custom_folder]);
                register("CustomFolder");
                resetField("CustomFolder", {defaultValue: ""});
              }
            }}
            sx={{backgroundColor: "rgb(162 162 162)", color: "white"}}>Add</Button>
        </Stack>
      </Stack> : <p>Please set the upload details before customizing the folder location.</p>}
      </>
    </Suspense>;
  };

  const RelatedDocs = (p: any) => {
    const [matchedDocs, setMatchedDocs] = useState<any>([]);

    useEffect(() => {
      const gatherMatches = p?.props?.possibleMatches
        ?.map((doc: any) => {
          if (doc?.match) {
            return {...doc, id: uuidv4()};
          }
        })
        .filter((doc: any) => doc !== undefined);
      setMatchedDocs(gatherMatches);
    }, [p]);

    const matchColumns = [{
      field: "market_name",
      id: "market_name",
      headerName: "Market",
      flex: 1,
      // width: "120",
      renderCell: (params: { row: { market_name: string } }) => {
        return params.row.market_name;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "callsign",
      flex: 1,
      // width: "90",
      renderCell: (params: { row: { callsign: string } }) => {
        return params.row.callsign;
      }
    }, {
      field: "candidate_campaign_name",
      id: "candidate_campaign_name",
      headerName: "Campaign",
      flex: 2,
      renderCell: (params: { row: { candidate_campaign_name: string } }) => {
        return params.row.candidate_campaign_name;
      }
    }, {
      field: "link",
      id: "link",
      headerName: "File",
      flex: 2,
      renderCell: (params: { row: { name: string, file_id: string, link: string } }) => {
        return params.row.link ? <Link to={`/political-candidates-and-issues/document/${params.row.file_id}`}
          style={{
            color: "#1976d2",
          }} rel="noreferrer"
        >{params.row.name.toString()}</Link> : params.row.name.toString();
      }
    }, {
      field: "upload_date",
      id: "upload_date",
      headerName: "Uploaded",
      flex: 1,
      // width: "110",
      renderCell: (params: { row: { upload_date: string } }) => {
        return convertDatePretty(params.row.upload_date, false);
      }
    }];
    // return <h1>test</h1>;
    return <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={200}/>}>
      <Table {...{
        tableKey: "matchedTable",
        mobile: props.mobile,
        data: matchedDocs && matchedDocs.length > 0 ? matchedDocs : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: matchColumns,
        loading: false,
        error: matchedDocs.length <= 0 ? true : false,
        errorMessage: `No documents found for ${p.props.advertiser}`,
        minHeight: matchedDocs.length <= 0 ? "100px" : "400px",
      }} /></Suspense>;
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const SubTableRowComponent = (p: { props: any }) => {
    const accordion: { name: string, title: string, disabled: boolean, expanded: boolean, component: any }[] = [{
      name: "upload_details",
      title: "Upload Details",
      expanded: true,
      disabled: false,
      component: <UploadDetails {...p} />
    }, {
      name: "folder_name",
      title: "Custom Folder Name & Location",
      expanded: false,
      disabled: false,
      component: <CustomFolderPath {...p} />
    }, {
      name: "manage_documents",
      title: "View & Remove Selected Documents",
      expanded: false,
      disabled: files && !files[p?.props.csv_id]?.cleanFiles ? true : false,
      component: <SelectedFiles {...p} />
    }, {
      name: "associated_documents",
      title: "Associated Documents",
      expanded: false,
      disabled: false,
      component: <RelatedDocs {...p} />
    }];

    return p ? accordion.map((item: {
      name: string,
      title: string,
      disabled: boolean,
      expanded: boolean,
      component: any
    }) => <Accordion
      key={uuidv4()}
      expanded={item.expanded ? item.expanded : expanded === item.name}
      onChange={handleChange(item.name)}
      disabled={item.disabled}
      sx={{
        // Styles applied to the Accordion root element
        backgroundColor: "#f9f9f9", // Default background color
        "&.Mui-expanded": {
          // Styles applied when the Accordion is expanded
          backgroundColor: "#e0f7fa", // Change background color when expanded
          // Add any other styles you want for the expanded state here
        },
        // "&.Mui-disabled": {
        //   // Styles applied when the Accordion is expanded
        //   backgroundColor: "#f5f5f5", // Change background color when expanded
        //   // Add any other styles you want for the expanded state here
        // },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={item.name}
        id={item.name}>{item.title}</AccordionSummary>
      <AccordionDetails>
        <div onClick={(event) => event.stopPropagation()}>
          {item.component}
        </div>
      </AccordionDetails>
    </Accordion>) : <></>;
  };

  const collapse_columns = [{
    field: "market",
    id: "market",
    headerName: "Market",
    flex: 1,
    renderCell: (params: { fcc_markets: { market: string, facId: string } }) => {
      return (<Link
        to={`/market/${getCallsign(params?.fcc_markets?.facId)?.market}/`}>{getCallsign(params?.fcc_markets?.facId)?.market}</Link>);
    }
  }, {
    field: "brand",
    id: "brand",
    headerName: "Brand",
    maxWidth: "60px",
    align: "left",
    sortable: false,
    editable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: { market: string, fcc_markets: { calls: string, facId: string, service: string } }) =>
      <Brand {...{
        callsign: params?.fcc_markets?.calls,
        facility_id: params?.fcc_markets?.facId,
        service: params?.fcc_markets?.service
      }} />
  }, {
    field: "callsign",
    id: "callsign",
    headerName: "Call Sign",
    maxWidth: "120px",
    renderCell: (params: { fcc_markets: { facId: string } }) => {
      return (<Link
        to={`/facility/${params?.fcc_markets?.facId}/`}>{getCallsign(params?.fcc_markets?.facId)?.callsign}</Link>);
    }
  }, {
    field: "date_created",
    id: "date_created",
    headerName: "Date Created",
    maxWidth: "100px",
    renderCell: (params: { date_created: string }) => {
      return convertDatePretty(params?.date_created);
    },
  }, {
    field: "flight_start",
    id: "flight_start",
    headerName: "Flight Start",
    maxWidth: "100px",
    renderCell: (params: { flight_start: string }) => {
      return convertDatePretty(params?.flight_start);
    },
  }, {
    field: "flight_end",
    id: "flight_end",
    headerName: "Flight End",
    maxWidth: "90px",
    renderCell: (params: { flight_end: string }) => {
      return convertDatePretty(params?.flight_end);
    },
  }, {
    field: "order_number",
    id: "order_number",
    headerName: "WO Order #",
    maxWidth: "100px",
    renderCell: (params: { order_number: string }) => {
      return params?.order_number;
    }
  }, {
    field: "advertiser",
    id: "advertiser",
    headerName: "Advertiser",
    renderCell: (params: { advertiser: string }) => {
      return params?.advertiser;
    }
  }, {
    field: "files",
    id: "files",
    headerName: "Add Files",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params: {
      id: string,
      csv_id: string,
      fcc_markets: { calls: string, facId: string, service: string }
    }) => {
      return <MediaUploaderDropZone
        name={`files.${params.csv_id}.files`}
        control={control}
        validationMessage={"Select at least one document for upload"}
        mediaType={{
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
          "application/rtf": [".rtf"],
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
          "text/plain": [".txt"],
        }}
        message={"Click to choose files or drag and drop"}
        required={true}
        width={"350px"}
        fileCount={files[params.csv_id]?.files?.length}
        onFileChange={async (file: any) => {
          // Logic to append new files to the uploads data
          const newVals: any = [];
          const cleanFiles: any = [];
          newVals.push(...file);

          file.map((f: any) => {
            cleanFiles.push({file: f, status: null, id: f.lastModified});
          });

          if (files[params.csv_id]?.files?.length > 0) {
            newVals.push(...files[params.csv_id].files);
            cleanFiles.push(...files[params.csv_id].cleanFiles);
          }

          const data = files[params.csv_id];
          data.files = newVals;
          data.cleanFiles = cleanFiles;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await parentSetValue(`Files.${params.csv_id}`, data, {shouldValidate: true});
          // await setValue(`Files.${params.fcc_markets.facId}.files`, newVals, {shouldValidate: true});

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // await setValue(`Files.${params.fcc_markets.facId}.cleanFiles`, cleanFiles, {shouldValidate: true});
        }}
      />;
    }
  }, {
    field: "validates",
    id: "validates",
    headerName: "Status",
    renderCell: (params: { csv_id: string, fcc_markets: { calls: string, facId: string, service: string } }) => {
      return files[params.csv_id]?.cleanFiles === null ? "Add Documents" : files[params.csv_id]?.validates === null || files[params.csv_id]?.validates === false ? "Add Upload Details" : files[params.csv_id]?.validates === true ? "Complete" : null;
    }
  }];

  return (
    <form onSubmit={handleSubmit(onSubmit)} name={"political_candidates_and_issues_bulk"}>
      <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 2}} sx={{padding: "20px"}}>
        <Grid item xs={12}>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Years",
              label: "Year",
              multiSelect: false,
              validationMessage: "Select a year",
              options: yearOptions,
              required: true,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Markets",
              label: "Markets",
              multiSelect: true,
              validationMessage: "Select at least one market",
              options: marketNames && marketNames.length > 0 ? marketNames : [],
              disabled: true,
              required: false,
              handleChange: onSubmit,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Callsigns",
              label: "Callsigns",
              multiSelect: true,
              validationMessage: "Select at least one callsign",
              options: newCallsigns && newCallsigns.length > 0 ? newCallsigns : [],
              disabled: true,
              required: false,
              handleChange: onSubmit,
              control: control
            }}  />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Document Upload:</strong></p>
          <CollapseTable {...{
            data: allCsvData?.length > 0 ? allCsvData : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: props.mobile ? collapse_columns : collapse_columns,
            pending: false,
            tableKey: "politicalMatchingTablev2",
            mobile: props.mobile,
            resetPagination: resetPagination,
            // tableSortBy: "order_number",
            // subTableColumns: columns,
            subTableComponent: SubTableRowComponent,
            subTableKey: "possibleMatchesv2",
            // subTableSortBy: "score",
            loading: false,
            error: false,
            errorMessage: ""
          }} />
        </Grid>
      </Grid>
      <ModalFooter {...{
        buttons: {
          left: [],
          right: [{
            variant: "contained",
            color: null,
            name: "Upload To FCC",
            type: "submit",
          }]
        },
        close: props.handleChange,
        submitButtonState: marketCallsigns.length === 0 && submitted !== false ? true : false
      }} />
    </form>
  );
}