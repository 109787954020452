// Using the custom local storage hook
// import {useLocalStorage} from "../hooks/useLocalStorage.tsx";
// const {setItem, getItem, removeItem} = useLocalStorage('someKey');
// Key = Table Name
// import {persistTablePagination} from "hooks/persistTablePagination.tsx";
// const {resetTable, setTablePageSize, getTablePageSize, setTablePageIndex, getTablePageIndex, removeItem} = persistTablePagination("issuesTable");
// setTablePageSize(10);
// setTablePageIndex(5);


// Items per page
// Last page index
export const persistTablePagination = (key: string) => {

  const getTableObject = () => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : window.localStorage.setItem(key, JSON.stringify({
        pageSize: 10,
        page: 0
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const resetTable = () => {
    try {
      const existingData = getTableObject();
      existingData["pageSize"] = getTablePageSize();
      existingData["page"] = 0;
      window.localStorage.setItem(key, JSON.stringify(existingData));
    } catch (error) {
      console.log(error);
    }
  };
  const setTablePageSize = (value: unknown) => {
    try {
      const existingData = getTableObject();
      existingData["pageSize"] = value;
      window.localStorage.setItem(key, JSON.stringify(existingData));
    } catch (error) {
      console.log(error);
    }
  };
  const getTablePageSize = () => {
    try {
      const existingData = getTableObject();
      return existingData["pageSize"];
    } catch (error) {
      console.log(error);
    }
  };

  const setTablePageIndex = (value: unknown) => {
    try {
      const existingData = getTableObject();
      existingData["page"] = value;
      window.localStorage.setItem(key, JSON.stringify(existingData));
    } catch (error) {
      console.log(error);
    }
  };

  const getTablePageIndex = () => {
    try {
      const existingData = getTableObject();
      return existingData["page"] ? existingData["page"] : 0;
    } catch (error) {
      console.log(error);
    }
  };

  // const getTableItem = () => {
  //   try {
  //     const item = window.localStorage.getItem(key);
  //     return item ? JSON.parse(item) : undefined;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const removeItem = () => {
  //   try {
  //     window.localStorage.removeItem(key);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return {getTableObject, resetTable, setTablePageSize, getTablePageSize, setTablePageIndex, getTablePageIndex};
};