import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
const useStyles = () => {
  const
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    backgroundImage = "https://images.unsplash.com/photo-1621947081720-86970823b77a?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    backgroundColor = "#050508";

  return {
    documents: {
      paddingTop: "10px",
      paddingBottom: "10px"
    },
    tableHeader: {
      // backgroundColor: "#EEE",
      // color: "black",
      position: "sticky",
    }
  };
};

export {useStyles};